import { Store as VuexStore } from 'vuex'
import url from './url'
import clan from './clan'
import accounts from './accounts'

const Store = () => {
  return new VuexStore({
    modules: {
      url,
      clan,
      accounts
    },
    state: {
      loading: false,
      progress: undefined,
      animations: true
    },
    mutations: {
      loading (state, loading) {
        if (loading === true || loading === false) {
          state.loading = loading
        } else if (loading < 100) {
          state.loading = true
          state.progress = loading
        } else {
          state.loading = false
          state.progress = undefined
        }
      },
      activateAnimations (state) {
        state.animations = !state.animations
      }
    },
    getters: {
      loading (state) {
        return state.loading
      },
      progress (state) {
        return state.progress
      },
      animations (state) {
        return state.animations
      }
    }
  })
}

export default Store
