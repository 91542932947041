<template>
  <div>
    <input ref="search" @keyup.enter="search" v-model="username" :placeholder="$t('search.placeholder')"/>
    <dropdown-select :title="$t('search.refine')" class="icon-select" small v-model="options" @select="x => selectedPlatform = x.value">
      <template #default>
        <font-awesome class="icon" icon="search"/>
      </template>
      <template #option-1>
        <xbox-icon class="icon" />
      </template>
      <template #option-2>
        <playstation-icon class="icon" />
      </template>
      <template #option-3>
        <steam-icon class="icon" />
      </template>
      <template #option-4>
        <stadia-icon class="icon" />
      </template>
      <template #option-5>
        <epic-icon class="icon" />
      </template>
    </dropdown-select>
    <button @click.prevent="search" class="submit is-hidden-mobile">{{ $t('search.search') }}</button>
    <button @click.prevent="search" class="submit-mobile is-hidden-tablet">
      <font-awesome icon="search"/>
    </button>
    <small>{{ $t('search.legacyAndSteamSupport') }}</small>
  </div>
</template>

<script>
import DropdownSelect from './DropdownSelect'
import debounce from '../helpers/debounce'
import EpicIcon from '../assets/platforms/epic.svg'
import StadiaIcon from '../assets/platforms/stadia.svg'
import SteamIcon from '../assets/platforms/steam.svg'
import PlaystationIcon from '../assets/platforms/playstation.svg'
import XboxIcon from '../assets/platforms/xbox.svg'
import { cleanUsername } from '../helpers/displayName'

export default {
  components: {
    DropdownSelect,
    EpicIcon,
    StadiaIcon,
    SteamIcon,
    PlaystationIcon,
    XboxIcon
  },
  data () {
    return {
      username: '',
      selectedPlatform: null,
      options: [
        { value: null, name: this.$t('search.allPlatforms') },
        { value: 1, name: this.$t('search.xbox') },
        { value: 2, name: this.$t('search.playstation') },
        { value: 3, name: this.$t('search.steam') },
        { value: 5, name: this.$t('search.stadia') },
        { value: 6, name: this.$t('search.epic') }
      ]
    }
  },
  directives: {
    debounce
  },
  methods: {
    async search () {
      if (this.username !== '') {
        this.$refs.search.blur()
        const username = this.username
        this.username = ''
        await this.$store.dispatch('accounts/loadFromUsername', { displayName: cleanUsername(username), membershipType: this.selectedPlatform })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

.search {
  margin-bottom: 3em;
  width: 100%;
  height: 60px;
  position: relative;
}

.icon-select {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

.icon {
  margin: 0 22px;
  width: 16px;
  height: 16px;
  color: #14171a;
}

.search input {
  border: none;
  height: 60px;
  width: 100%;
  font-family: Roboto, Arial, 'Helvetica Neue', sans-serif;
  font-size: 18px;
  color: #424242;
  border-radius: 30px;
  background: #ffffff none;
  padding: 10px 150px 10px 60px;
}

small {
  color: #fff;
  font-size: 12px;
  text-align: center;
  width: 100%;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .search input {
    padding-right: 60px;
  }
}

.submit, .submit-mobile {
  width: 150px;
  height: 60px;
  border-radius: 30px;
  position: absolute;
  text-align: center;
  top: 0;
  right: 0;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 4px;
  padding-left: 0.625rem;
  color: #ffffff;
  text-shadow: 2px 1px 6px rgba(0, 0, 0, 0.21);
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.6);
  background-image: linear-gradient(163deg, $global, $globalGradient 99%);
  border: solid 3px #ffffff;
  cursor: pointer;
}

.submit-mobile {
  width: 60px;
  padding: 0;
  font-size: 24px;
  font-weight: normal;
}

.submit:hover, .submit-mobile:hover {
  box-shadow: inset 0 0 150px 0 rgba(255, 255, 255, 0.2);
}

.submit:active, .submit-mobile:active {
  box-shadow: inset 0 0 150px 0 rgba(255, 255, 255, 0.8);
}
</style>
