<template>
  <div class="leaderboard card">
    <div class="card-header" :class="gameVersion === GameVersion.DESTINY2 ? 'destiny2' : 'destiny'">
      <h2 class="card-header-title">{{ gameVersion === GameVersion.DESTINY2 ? 'DESTINY 2' : 'DESTINY' }}</h2>
      <span class="card-header-total"><font-awesome class="small-icon" icon="user" />{{ total }}</span>
    </div>
    <div class="card-content">
      <div class="tabs">
        <ul>
          <li v-if="gameVersion === GameVersion.DESTINY2" class="global is-active">
            <a @click="changeMembershipType(MembershipType.UNKNOWN)">
              <font-awesome class="icon" icon="gamepad" />{{ $t('leaderboard.all') }}
            </a>
          </li>
          <li v-if="gameVersion === GameVersion.DESTINY" class="playstation" :class="{ 'is-active': membershipType === MembershipType.PLAYSTATION }">
            <a @click="changeMembershipType(MembershipType.PLAYSTATION)">
              <playstation-icon class="icon" />
            </a>
          </li>
          <li v-if="gameVersion === GameVersion.DESTINY" class="xbox" :class="{ 'is-active': membershipType === MembershipType.XBOX }">
            <a @click="changeMembershipType(MembershipType.XBOX)">
              <xbox-icon class="icon" />
            </a>
          </li>
        </ul>
      </div>
      <dropdown-select class="filter" right v-model="options" @select="changeSorting">
        <template #default>
          <font-awesome class="filter-icon" icon="hourglass-half"/>
        </template>
        <template #option-1>
          <font-awesome class="filter-icon" icon="trash"/>
        </template>
        <template #option-2>
          <font-awesome class="filter-icon" icon="bookmark"/>
        </template>
        <template #option-3>
          <font-awesome class="filter-icon" icon="shield-alt"/>
        </template>
        <template #caret>
          <font-awesome icon="sort"/>
        </template>
      </dropdown-select>
      <div v-if="page && !loading" class="leaderboard-container">
        <ul class="players">
          <li class="player" v-for="(player, index) in page.players" :key="player.membershipId">
            <span>
              <small>{{ (index + 1) + ((pageNumber - 1) * 10) }}</small>
              <span v-if="gameVersion === GameVersion.DESTINY2">
                <xbox-icon v-if="player.membershipType === MembershipType.XBOX" class="small-icon" />
                <playstation-icon v-if="player.membershipType === MembershipType.PLAYSTATION" class="small-icon" />
                <steam-icon v-if="player.membershipType === MembershipType.STEAM" class="small-icon" />
                <stadia-icon v-if="player.membershipType === MembershipType.STADIA" class="small-icon" />
                <epic-icon v-if="player.membershipType === MembershipType.EPIC" class="small-icon" />
              </span>
              <a href="#" @click="loadAccount(player.membershipId)">{{ player.displayName }}</a>
            </span>
            <span class="time-played">{{ ['timePlayed', 'timeWasted'].includes(sorting) ? player.time : player.number }}</span>
          </li>
        </ul>
        <leaderboard-pagination @pagechanged="changePage" :current-page="pageNumber" :total="page.totalPlayersPlatform" :total-pages="Math.ceil(page.totalPlayersPlatform / 10)"/>
      </div>
      <small-loader v-if="loading" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import GameVersion from '../helpers/gameVersion'
import MembershipType from '../helpers/membershipType'
import numberFormat from '../helpers/numberFormat'
import Page from '../models/page'
import LeaderboardPagination from './LeaderboardPagination'
import SmallLoader from './SmallLoader'
import DropdownSelect from './DropdownSelect'
import StadiaIcon from '../assets/platforms/stadia.svg'
import EpicIcon from '../assets/platforms/epic.svg'
import SteamIcon from '../assets/platforms/steam.svg'
import PlaystationIcon from '../assets/platforms/playstation.svg'
import XboxIcon from '../assets/platforms/xbox.svg'

export default {
  components: {
    LeaderboardPagination,
    SmallLoader,
    DropdownSelect,
    StadiaIcon,
    EpicIcon,
    SteamIcon,
    PlaystationIcon,
    XboxIcon
  },
  computed: {
    cacheKey () {
      return `${this.membershipType}_${this.pageNumber}_${this.sorting}_${this.clan}`
    },
    total () {
      return numberFormat(this.page?.totalPlayers || 0)
    }
  },
  data () {
    const options = [
      { value: 'timePlayed', name: this.$t('leaderboard.timePlayed') },
      { value: 'timeWasted', name: this.$t('leaderboard.timeWasted') }
    ]

    if (this.gameVersion === GameVersion.DESTINY2) {
      options.push({ value: 'triumph', name: this.$t('leaderboard.triumph') })
      options.push({ value: 'seals', name: this.$t('leaderboard.seals') })
    }

    return {
      GameVersion,
      MembershipType,
      loading: true,
      membershipType: this.gameVersion === GameVersion.DESTINY ? MembershipType.PLAYSTATION : MembershipType.UNKNOWN,
      pageNumber: 1,
      cache: {},
      page: null,
      options,
      sorting: 'timePlayed'
    }
  },
  methods: {
    async changeMembershipType (membershipType) {
      if (this.loading) {
        return
      }

      this.membershipType = membershipType
      this.pageNumber = 1
      await this.loadPage()
    },
    async changePage (page) {
      if (this.loading) {
        return
      }

      this.pageNumber = page
      await this.loadPage()
    },
    async changeSorting (sorting) {
      if (this.loading) {
        return
      }

      this.sorting = sorting.value
      await this.loadPage()
    },
    async loadPage () {
      this.loading = true

      if (!this.cache[this.cacheKey]) {
        this.cache[this.cacheKey] = axios.get(`${process.env.VUE_APP_API}/leaderboard/${this.gameVersion}/${this.membershipType}/${this.pageNumber}/?sorting=${this.sorting}&clan=${this.clan}`)
      }

      const json = await this.cache[this.cacheKey]
      this.page = Page.fromJson(json.data)
      this.loading = false
    },
    async loadAccount (membershipId) {
      await this.$store.dispatch('accounts/load', { membershipType: this.membershipType, membershipId })
    }
  },
  props: {
    gameVersion: {
      type: Number,
      required: true
    },
    clan: {
      type: Number,
      default: 0
    }
  },
  async created () {
    await this.loadPage()
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

.leaderboard .card-header {
  height: 80px;
  position: relative;
}

.leaderboard .card-header-title {
  font-weight: 300;
  font-size: 22px;
  letter-spacing: 6px;
  padding: 1.5em;
  background: rgba(255, 255, 255, 0.5);
}

.leaderboard .card-header-total {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2em;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #000;
  text-shadow: 0 0 2px #ffffff;
}

.leaderboard .card-header.destiny2 {
  background: #ced5cd url('../assets/leaderboard/destiny2.png') right no-repeat;
}

.leaderboard .card-header.destiny {
  background: #d3dbe0 url('../assets/leaderboard/destiny.png') right no-repeat;
}

.leaderboard .card-content {
  min-height: 470px;
}

.leaderboard .filter {
  position: absolute;
  top: 104px;
  right: 24px;
  height: 42px;
  width: 42px;
}

.leaderboard .filter::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: $global;
}

.leaderboard .tabs ul {
  border-bottom: 2px solid #e5e5e5;
  height: 42px;
}

.leaderboard .tabs a {
  color: #3b3b3b;
  font-weight: 800;
  border-bottom-width: 2px;
  margin-bottom: -2px;
}

.leaderboard .tabs li.is-active a {
  border-bottom-color: $global;
}

.leaderboard .tabs li.is-active.stadia a {
  border-bottom-color: $stadiaLight;
}

.leaderboard .tabs li.is-active.epic a {
  border-bottom-color: $epicLight;
}

.leaderboard .tabs li.is-active.steam a {
  border-bottom-color: $steamLight;
}

.leaderboard .tabs li.is-active.playstation a {
  border-bottom-color: $playstationLight;
}

.leaderboard .tabs li.is-active.xbox a {
  border-bottom-color: $xboxLight;
}

.icon {
  width: 24px;
  margin: 0 0.5em;
  color: #3b3b3b;
  fill: #3b3b3b;
}

.small-icon {
  width: 18px;
  margin: 0 0.5em;
}

.filter-icon {
  width: 20px;
  height: 20px;
  font-size: 16px;
  margin: 0 12px;
  color: #3b3b3b;
  fill: #3b3b3b;
}

.leaderboard-container {
  position: relative;
}

.players {
  margin-bottom: 1em;
}

.player {
  margin-bottom: 0.5em;
  display: flex;
}

.player > span:not(.time-played) {
  display: flex;
  flex: 1;
  align-items: center;
}

.player > span:not(.time-played) > span {
  display: flex;
  align-items: center;
}

.player small {
  vertical-align: middle;
  height: 22px;
  width: 1rem;
  display: inline-block;
  text-align: right;
  margin-right: 1rem;
}

.player a {
  color: $global;
}

.player .time-played {
  float: right;
}
</style>
