import check from 'check-types'
import timeFormat from '../helpers/timeFormat'

class Character {
  constructor () {
    this.bungieWebsitePath = 'https://www.bungie.net'
    this.characterId = ''
    this.deleted = false
    this.seconds = 0
    this.pvpKda = 0
    this.pveKda = 0
    this.race = ''
    this.gender = ''
    this.class = ''
    this.power = 0
    this.level = 0
    this.legend = ''
    this.emblemPath = ''
    this.backgroundPath = ''
    this.title = null
    this.gilded = false
    this.gildedCount = 0
  }

  get timePlayed () {
    return timeFormat(this.seconds)
  }

  get timeAfk () {
    return timeFormat(this.secondsAfk)
  }

  get displayName () {
    return this.deleted ? 'Deleted' : this.race + this.class
  }

  get emblemImage () {
    return this.deleted ? '' : this.emblemPath && this.bungieWebsitePath + this.emblemPath
  }

  get backgroundImage () {
    return this.deleted ? '' : this.backgroundPath && this.bungieWebsitePath + this.backgroundPath
  }

  static fromJson (json) {
    validateJsonFormat(json)

    const character = new Character()
    character.characterId = json.characterId
    character.deleted = json.deleted
    character.seconds = json.timePlayed
    character.secondsAfk = json.timeAfk
    character.pvpKda = json.pvpKda
    character.pveKda = json.pveKda
    character.race = json.race
    character.gender = json.gender
    character.class = json.class
    character.power = json.power
    character.level = json.level
    character.legend = json.legend !== null ? json.legend : 'N/A'
    character.emblemPath = json.emblemPath
    character.backgroundPath = json.backgroundPath
    character.title = json.title
    character.gilded = json.gilded
    character.gildedCount = json.gildedCount
    return character
  }
}

function validateJsonFormat (json) {
  if (check.not.object(json)) throw new Error('JSON must be an object')
  if (check.not.string(json['characterId'])) throw new Error('CharacterId must be a string')
  if (check.not.boolean(json['deleted'])) throw new Error('Deleted must be a boolean')
  if (check.not.number(json['timePlayed'])) throw new Error('TimePlayed must be an number')
  if (check.not.number(json['pvpKda'])) throw new Error('PvpKda must be an number')
  if (check.not.number(json['pveKda'])) throw new Error('PveKda must be an number')
  if (check.not.string(json['race']) && !json['deleted']) throw new Error('Race must be a string')
  if (check.not.string(json['gender']) && !json['deleted']) throw new Error('Gender must be a string')
  if (check.not.string(json['class']) && !json['deleted']) throw new Error('Class must be a string')
  if (check.not.number(json['power']) && !json['deleted']) throw new Error('Power must be an number')
}

export default Character
