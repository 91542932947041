<template>
  <div class="patreon">
    <div class="container is-medium">
      <a class="logo" href="https://wastedondestiny.com">
        <main-logo />
      </a>
    </div>
    <div class="container is-small" v-if="!isSaved">
      <simple-card>
        <template #header>
          <simple-card-header>
            Patreon link
            <template #right>
              <template v-if="!patreon">Required</template>
              <a @click="removePatreon" class="close" v-else><font-awesome icon="times" /></a>
            </template>
          </simple-card-header>
        </template>
        <template v-if="patreon">
          <h2 class="name"><patreon-icon class="icon" />{{ patreon.data.attributes.full_name }}</h2>
          <button class="refresh" @click="refreshPatreon"><font-awesome icon="redo" /></button>
          <span v-if="patreonPledge">You are pledged on the following tier: {{ patreonPledge }}</span>
          <span class="warning" v-else>You are not a member of Wasted on Destiny: <a href="https://www.patreon.com/wastedondestiny?fan_landing=true" target="_blank">pledge here!</a></span>
        </template>
        <template v-else>
          <span>Please allow use to read your Patreon account to check your subscription status.</span>
          <simple-button class="block" @click="redirect(patreonLink)">Connect with Patreon</simple-button>
        </template>
      </simple-card>
      <simple-card>
        <template #header>
          <simple-card-header>
            Bungie link
            <template v-if="patreon" #right>
              <template v-if="patreon && patreonPledge && !bungie && !discord">Only one required</template>
              <template v-if="patreon && patreonPledge && !bungie && discord">Optional</template>
              <a @click="removeBungie" class="close" v-if="bungie"><font-awesome icon="times" /></a>
            </template>
          </simple-card-header>
        </template>
        <template v-if="bungie">
          <h2 class="name"><tricorn-icon class="icon" />{{ bungie.bungieNetUser.uniqueName }}</h2>
          <button class="refresh" @click="refreshBungie"><font-awesome icon="redo" /></button>
          <span v-if="bungie.accounts.length">You have the following accounts:</span>
          <ul v-if="bungie.accounts.length">
            <li v-for="(account, i) in bungie.accounts" :key="i">
              <playstation-icon class="icon" v-if="account.membershipType === 2" />
              <xbox-icon class="icon" v-if="account.membershipType === 1" />
              <steam-icon class="icon" v-if="account.membershipType === 3" />
              <stadia-icon class="icon" v-if="account.membershipType === 5" />
              <epic-icon class="icon" v-if="account.membershipType === 6" />
              {{ account.displayName }}
              <template v-if="account.gameVersion === 1"><small> (Destiny 1)</small></template>
            </li>
          </ul>
        </template>
        <template v-else>
          <span>Link your Bungie account and review your Destiny memberships linked to that account.</span>
          <simple-button class="block" :disabled="buttonDisabled" @click="redirect(bungieLink)">Connect with Bungie</simple-button>
        </template>
      </simple-card>
      <simple-card>
        <template #header>
          <simple-card-header>
            Discord link
            <template v-if="patreon" #right>
              <template v-if="patreon && patreonPledge && !discord && !bungie">Only one required</template>
              <template v-if="patreon && patreonPledge && !discord && bungie">Optional</template>
              <a @click="removeDiscord" class="close" v-if="discord"><font-awesome icon="times" /></a>
            </template>
          </simple-card-header>
        </template>
        <template v-if="discord">
          <h2 class="name"><font-awesome :icon="['fab', 'discord']" class="icon" />{{ discord.username }}#{{ discord.discriminator }}</h2>
          <button class="refresh" @click="refreshDiscord"><font-awesome icon="redo" /></button>
          <span>If you have not yet joined the server, you may click <a href="https://dsc.gg/wastedondestiny" target="_blank">here!</a></span>
        </template>
        <template v-else>
          <span>Link your Discord account to allow us to assign you a role on the Discord server.</span>
          <simple-button class="block" :disabled="buttonDisabled" @click="redirect(discordLink)">Connect with Discord</simple-button>
        </template>
      </simple-card>
      <simple-button class="block" :disabled="buttonDisabled || (!discord && !bungie)" @click="save">Link accounts</simple-button>
    </div>
    <div class="container is-small" v-else>
      <simple-card>
        <template #header>
          <simple-card-header>
            Account linking successful
          </simple-card-header>
        </template>
        <h2 class="name">Thank you for joining us!</h2>
      </simple-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import SimpleCard from '../components/SimpleCard'
import SimpleCardHeader from '../components/SimpleCardHeader'
import TricornIcon from '../assets/tricorn.svg'
import PatreonIcon from '../assets/patreon.svg'
import MainLogo from '../components/MainLogo'
import SimpleButton from '../components/SimpleButton'
import PlaystationIcon from '../assets/platforms/playstation.svg'
import XboxIcon from '../assets/platforms/xbox.svg'
import SteamIcon from '../assets/platforms/steam.svg'
import StadiaIcon from '../assets/platforms/stadia.svg'
import EpicIcon from '../assets/platforms/epic.svg'

export default {
  components: {
    SimpleCard,
    SimpleCardHeader,
    TricornIcon,
    PatreonIcon,
    MainLogo,
    SimpleButton,
    PlaystationIcon,
    XboxIcon,
    SteamIcon,
    StadiaIcon,
    EpicIcon
  },
  data () {
    return {
      discord: null,
      patreon: null,
      bungie: null,
      patreonLink: `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=${process.env.VUE_APP_PATREON_ID}&redirect_uri=https://wastedondestiny.com/patreon&scope=identity%20identity%5Bemail%5D%20identity.memberships%20campaigns&state=patreon`,
      discordLink: `https://discord.com/api/oauth2/authorize?response_type=code&client_id=${process.env.VUE_APP_DISCORD_ID}&redirect_uri=https://wastedondestiny.com/patreon&scope=identify&state=discord`,
      bungieLink: `https://www.bungie.net/en/oauth/authorize?response_type=code&client_id=${process.env.VUE_APP_BUNGIE_ID}&state=bungie`,
      isSaved: false
    }
  },
  computed: {
    patreonPledge () {
      if (this.patreon) {
        const campaign = this.patreon.included.find(x => x.type === 'campaign' && x.id === process.env.VUE_APP_PATREON_CAMPAIGN)

        if (campaign) {
          const creatorId = campaign.relationships.creator.data.id
          const pledge = this.patreon.included.find(x => x.type === 'pledge' && x.relationships.creator.data.id === creatorId)

          if (pledge && !pledge.attributes.declined_since) {
            const reward = this.patreon.included.find(x => x.type === 'reward' && x.id === pledge.relationships.reward.data.id)

            if (reward) {
              const formatter = new Intl.NumberFormat('en-CA', {
                style: 'currency',
                minimumFractionDigits: 0,
                currency: reward.attributes.patron_currency
              })
              return `${reward.attributes.title} (${formatter.format(reward.attributes.amount_cents / 100)})`
            }
          }
        }

        if (this.patreon.data.relationships?.campaign?.data?.id === process.env.VUE_APP_PATREON_CAMPAIGN) {
          return 'Welcome back! You\'re the owner!'
        }
      }

      return null
    },
    buttonDisabled () {
      return !this.patreon || !this.patreonPledge
    }
  },
  methods: {
    redirect (url) {
      location.href = url
    },
    removePatreon () {
      localStorage.removeItem('patreon')
      this.patreon = null
    },
    removeBungie () {
      localStorage.removeItem('bungie')
      this.bungie = null
    },
    removeDiscord () {
      localStorage.removeItem('discord')
      this.discord = null
    },
    async refreshPatreon () {
      const patreonInfo = await this.getPatreonInfo()
      localStorage.setItem('patreon', JSON.stringify(patreonInfo))
      this.patreon = patreonInfo
    },
    async getPatreonInfo (token) {
      const result = token
        ? await axios.get(`${process.env.VUE_APP_API}/supporters/patreon/${token}`)
        : await axios.get(`${process.env.VUE_APP_API}/supporters/patreon`, {
          headers: {
            access_token: this.patreon.access_token
          }
        })
      return result.data
    },
    async refreshBungie () {
      const bungieInfo = await this.getBungieInfo()
      localStorage.setItem('bungie', JSON.stringify(bungieInfo))
      this.bungie = bungieInfo
    },
    async getBungieInfo (token) {
      const result = token
        ? await axios.get(`${process.env.VUE_APP_API}/supporters/bungie/${token}`)
        : await axios.get(`${process.env.VUE_APP_API}/supporters/bungie`, {
          headers: {
            access_token: this.bungie.access_token
          }
        })
      return result.data
    },
    async refreshDiscord () {
      const discordInfo = await this.getDiscordInfo()
      localStorage.setItem('discord', JSON.stringify(discordInfo))
      this.discord = discordInfo
    },
    async getDiscordInfo (token) {
      const result = token
        ? await axios.get(`${process.env.VUE_APP_API}/supporters/discord/${token}`)
        : await axios.get(`${process.env.VUE_APP_API}/supporters/discord`, {
          headers: {
            access_token: this.discord.access_token
          }
        })
      return result.data
    },
    async save () {
      const result = await axios.post(`${process.env.VUE_APP_API}/supporters`, {
        patreon: this.patreon,
        bungie: this.bungie,
        discord: this.discord
      })

      if (result.status === 200) {
        this.isSaved = true
        setTimeout(() => {
          location.href = 'https://wastedondestiny.com'
        }, 10000)
      }
    }
  },
  async mounted () {
    const params = new URLSearchParams(location.search)

    if (params.has('code') && params.has('state')) {
      switch (params.get('state')) {
        case 'patreon':
          const patreonInfo = await this.getPatreonInfo(params.get('code'))
          localStorage.setItem('patreon', JSON.stringify(patreonInfo))
          break
        case 'discord':
          const discordInfo = await this.getDiscordInfo(params.get('code'))
          localStorage.setItem('discord', JSON.stringify(discordInfo))
          break
        case 'bungie':
          const bungieInfo = await this.getBungieInfo(params.get('code'))
          localStorage.setItem('bungie', JSON.stringify(bungieInfo))
          break
      }
    }

    history.replaceState({}, null, location.origin + location.pathname)

    this.discord = localStorage.getItem('discord') ? JSON.parse(localStorage.getItem('discord')) : null
    this.patreon = localStorage.getItem('patreon') ? JSON.parse(localStorage.getItem('patreon')) : null
    this.bungie = localStorage.getItem('bungie') ? JSON.parse(localStorage.getItem('bungie')) : null
  }
}
</script>

<style scoped>
.patreon {
  padding: 0 24px;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-evenly;
}

.container.is-medium {
  display: flex;
  justify-content: center;
  align-items: center;
}

a.logo {
  width: 80vw;
}

.card {
  margin-bottom: 16px;
  padding-bottom: 0;
}

.close {
  position: absolute;
  top: -1px;
  right: 0;
  font-size: 16px;
  color: #ffffff;
}

.name {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 24px;
}

.warning {
  color: #eb2a2a;
}

.icon {
  width: 32px;
  margin-left: -2px;
  margin-right: 6px;
  vertical-align: middle;
  color: #000000;
}

li .icon {
  margin-left: 4px;
  width: 16px;
}

li small {
  font-size: 11px;
}

.refresh {
  position: absolute;
  top: 12px;
  right: 12px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.card span {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
}

.block {
  margin-top: 16px;
  margin-bottom: 8px;
  width: 100%;
}
</style>
