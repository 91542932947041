<template>
  <div v-click-outside="close" :class="{ right, small }">
    <a class="ds-button" href="#" @click.prevent="toggle">
      <slot :name="selected || firstOption" />
    </a>
    <span class="ds-caret">
      <slot name="caret">
        <font-awesome class="icon" icon="caret-down"/>
      </slot>
    </span>
    <div class="ds-options" v-if="open">
      <a class="ds-option" href="#" @click.prevent="select(option, i)" v-for="(option, i) in value" :key="i">
        <span class="ds-icon">
          <slot :name="optionKey(i)" />
        </span>
        <span>{{ option.name }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      open: false,
      selected: null
    }
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    right: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    options () {
      return this.$slots
    },
    firstOption () {
      return 'default'
    },
    parentWidth () {
      return this.$el.offsetWidth
    }
  },
  methods: {
    toggle () {
      this.open = !this.open
      this.$nextTick(() => {
        const element = this.$el.querySelector('.ds-options')
        if (element) element.style.setProperty('--width', `${this.parentWidth}px`)
      })
    },
    close () {
      this.open = false
    },
    select (option, i) {
      this.$emit('select', option)
      this.selected = this.optionKey(i)
      this.close()
    },
    optionKey (i) {
      return i ? `option-${i}` : 'default'
    },
    render (el) {
      return el
    },
    renderSelected (el) {
      return el
    }
  }
}
</script>

<style scoped>
.ds-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.right .ds-button {
  justify-content: flex-end;
}

.ds-caret {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.small .ds-caret {
  right: 10%;
}

.ds-options {
  --width: 0;
  background: #fff;
  position: relative;
  margin-top: 2px;
  width: 250px;
  padding: 4px 0;
  border-radius: 4px;
  z-index: 9999;
  box-shadow: 1px 1px 4px #000;
}

.right .ds-options {
  left: calc(-250px + var(--width));
}

.ds-options::after {
  content: '';
  display: block;
  position: absolute;
  top: -10px;
  left: calc(var(--width) / 2 - 5px);
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 5px;
}

.right .ds-options::after {
  left: calc(250px - var(--width) / 2 - 4px);
}

.ds-options::before {
  content: '';
  display: block;
  position: absolute;
  top: -12px;
  left: calc(var(--width) / 2 - 6px);
  border: solid transparent;
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: #000;
  border-width: 6px;
}

.right .ds-options::before {
  left: calc(250px - var(--width) / 2 - 5px);
}

.ds-option {
  color: #000;
  width: 100%;
  display: flex;
  padding: 8px;
  font-size: 14px;
  align-items: center;
}

.right .ds-option {
  flex-direction: row-reverse;
}

.ds-option:hover {
  background: rgba(0, 0, 0, 0.1);
}

.ds-icon {
  width: var(--width);
  margin-left: -8px;
  display: flex;
  align-items: center;
}

.right .ds-icon {
  flex-grow: 1;
  justify-content: flex-end;
  margin-left: unset;
  margin-right: -9px;
}
</style>
