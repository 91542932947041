import { render, staticRenderFns } from "./AdBlock.vue?vue&type=template&id=f2299258&scoped=true&"
import script from "./AdBlock.vue?vue&type=script&lang=js&"
export * from "./AdBlock.vue?vue&type=script&lang=js&"
import style0 from "./AdBlock.vue?vue&type=style&index=0&id=f2299258&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2299258",
  null
  
)

export default component.exports