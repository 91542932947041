<template>
  <div>
    <div class="header">
      <nav class="level is-hidden-mobile">
        <div class="level-left">
          <div class="level-item" v-if="showExtra">
            <a href="/">
              <small-logo class="logo"/>
            </a>
          </div>
          <div class="level-item padding" v-else></div>
        </div>
        <div class="level-item">
          <router-link class="link" :to="searchUrl">{{ $t('header.search') }}</router-link>
          <router-link class="link" :to="clanUrl">{{ $t('header.searchClan') }}</router-link>
          <router-link class="link" to="/leaderboard">{{ $t('header.leaderboard') }}</router-link>
          <router-link class="link" to="/about">{{ $t('header.about') }}</router-link>
        </div>
        <div class="level-right">
          <div class="level-item">
            <a
              class="link right"
              href="https://patreon.com/wastedondestiny"
              target="_blank"
              :title="$t('header.patreon')"
            >
              <patreon-icon class="patreon-logo" />
            </a>
            <a
              class="link right"
              href="https://dsc.gg/wastedondestiny"
              target="_blank"
              :title="$t('header.discord')"
            >
              <font-awesome :icon="[ 'fab', 'discord' ]"/>
            </a>
          </div>
        </div>
      </nav>
      <nav class="level is-hidden-tablet is-mobile">
        <div class="level-item mobile-links">
          <router-link class="mobile-link small-icon" :to="searchUrl" :title="$t('header.search')">
            <font-awesome icon="search"/>
            <small>{{ $t('header.search') }}</small>
          </router-link>
          <router-link class="mobile-link small-icon" :to="clanUrl" :title="$t('header.searchClan')">
            <font-awesome icon="users"/>
            <small>{{ $t('header.searchClan') }}</small>
          </router-link>
          <router-link class="mobile-link small-icon" to="/leaderboard" :title="$t('header.leaderboard')">
            <font-awesome icon="trophy"/>
            <small>{{ $t('header.leaderboard') }}</small>
          </router-link>
          <router-link class="mobile-link small-icon" to="/about" :title="$t('header.about')">
            <font-awesome icon="info-circle"/>
            <small>{{ $t('header.about') }}</small>
          </router-link>
        </div>
      </nav>
    </div>
    <ad-block v-if="showExtra" />
  </div>
</template>

<script>
import AdBlock from './AdBlock'
import SmallLogo from '../components/SmallLogo'
import PatreonIcon from '../assets/patreon.svg'

export default {
  components: {
    AdBlock,
    SmallLogo,
    PatreonIcon
  },
  computed: {
    searchUrl () {
      return '/' + this.$store.getters['url/get']
    },
    clanUrl () {
      return this.$store.getters['clan/get']
        ? '/clan/' + this.$store.getters['clan/get'].id
        : '/clan'
    },
    empty () {
      return this.$store.getters['accounts/count'] === 0
    },
    showExtra () {
      return !this.empty || '/' + this.$store.getters['url/get'] !== this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

.header {
  background: $headerColor;
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 1em;
}

.header > .level {
  height: 70px;
}

.level:not(:last-child) {
  margin-bottom: 0 !important;
}

a {
  color: #adadad
}

a.router-link-exact-active {
  color: $global;
}

.logo {
  margin-top: .25rem;
  width: 42px;
}

.patreon-logo {
  fill: currentColor;
  width: 1.25em;
  vertical-align: -0.25em;
}

.link {
  margin: 0 1.5em;
  letter-spacing: 2px;
}

.link.right {
  margin-left: 0;
}
.mobile-links {
  display: flex;
  justify-content: space-evenly;
}

.mobile-link {
  font-size: 24px;
  margin-left: 1em;
  margin-right: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;

  small {
    letter-spacing: 0.5px;
    padding-top: 4px;
    font-size: 12px;
  }
}

.small-icon {
  font-size: 20px;
  margin-left: 0;
  margin-right: 0;
}

.padding {
  width: 42px;
}
</style>
