<template>
  <div class="more">
    <template v-if="account.clan">
      <router-link class="clan" :to="'clan/' + account.clan.id">
        <font-awesome class="icon" icon="users" :title="$t('account.clan')" />
        <span>
          <strong>{{ account.clan.name }}</strong>
          <small>{{ account.clan.motto }}</small>
        </span>
      </router-link>
      <hr />
    </template>
    <div class="level is-mobile" v-if="account.gameVersion === 1">
      <div class="level-item triumph">
        <small>{{ $t('account.grimoire') }}</small>
        <span>{{ formatNumber(account.score) }} <span class="over">/ {{ formatNumber(5615) }}</span></span>
      </div>
      <div class="level-item last-seen" v-if="account.dateLastPlayed">
        <small>{{ $t('account.lastSeen') }}</small>
        <span>{{ account.dateLastPlayed }}</span>
      </div>
      <div class="level-item last-seen" v-if="account.currentlyPlaying">
        <small>{{ $t('account.playing') }}</small>
        <span>{{ account.currentlyPlaying }}</span>
      </div>
    </div>
    <div class="level is-mobile" v-if="account.gameVersion === 2">
      <div class="level-item triumph">
        <small>{{ $t('account.triumph') }}
          <the-popover :text="$t('account.triumphInfo')">&nbsp;<font-awesome icon="question-circle" /></the-popover>
        </small>
        <span>{{ formatNumber(account.score) }} <span class="over" v-if="account.maxScore">/ {{ formatNumber(account.maxScore) }}</span></span>
      </div>
      <div class="level-item triumph">
        <small>{{ $t('account.allTriumph') }}
          <the-popover :text="$t('account.allTriumphInfo')">&nbsp;<font-awesome icon="question-circle" /></the-popover>
        </small>
        <span>{{ formatNumber(account.legacyScore + account.score) }} <span class="over">/ <tricorn-icon class="over-icon" /></span></span>
      </div>
    </div>
    <div class="level is-mobile" v-if="account.gameVersion === 2">
      <div class="level-item triumph">
        <small>{{ $t('account.seals') }}
          <the-popover :text="$t('account.sealsInfo')">&nbsp;<font-awesome icon="question-circle" /></the-popover>
        </small>
        <span>{{ account.seals }}</span>
      </div>
      <div class="level-item last-seen" v-if="account.dateLastPlayed">
        <small>{{ $t('account.lastSeen') }}</small>
        <span>{{ account.dateLastPlayed }}</span>
      </div>
      <div class="level-item last-seen" v-if="account.currentlyPlaying">
        <small>{{ $t('account.playing') }}</small>
        <span>{{ account.currentlyPlaying }}</span>
      </div>
    </div>
    <v-wait v-if="account.gameVersion === 2" :for="detailKey">
      <div class="skeleton" slot="waiting">
        <small-loader />
      </div>
      <template v-if="account.lastMonth">
        <account-card-detail-breakdown :account="account" />
        <account-card-detail-activities :account="account" />
      </template>
      <template v-if="account.seasons">
        <account-card-detail-seasons :account="account" />
      </template>
    </v-wait>
    <hr v-else />
    <account-card-detail-character v-for="character in account.characters" :key="character.id" :account="account" :character="character" />
    <a class="bungie-link" target="_blank" :href="bungieLink">
      <i18n path="account.bungieNet">
        <strong>BUNGIE.NET</strong>
      </i18n>
    </a>
  </div>
</template>

<script>
import Account from '../models/account'
import AccountCardDetailSeasons from './AccountCardDetailSeasons'
import AccountCardDetailBreakdown from './AccountCardDetailBreakdown'
import AccountCardDetailActivities from './AccountCardDetailActivities'
import AccountCardDetailCharacter from './AccountCardDetailCharacter'
import TricornIcon from '../assets/tricorn.svg'
import ThePopover from './ThePopover'
import SmallLoader from './SmallLoader'
import GameVersion from '../helpers/gameVersion'

export default {
  components: {
    AccountCardDetailSeasons,
    AccountCardDetailBreakdown,
    AccountCardDetailActivities,
    AccountCardDetailCharacter,
    TricornIcon,
    ThePopover,
    SmallLoader
  },
  data () {
    return {
      bungieLink: `https://bungie.net/en/profile/${this.account.membershipType}/${this.account.membershipId}`,
      detailKey: `detail_${this.account.membershipType}_${this.account.membershipId}`
    }
  },
  props: {
    account: {
      type: Account,
      required: true
    }
  },
  methods: {
    formatNumber (number) {
      return number > 9999 ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : number.toString()
    }
  },
  async mounted () {
    if (this.account.gameVersion === GameVersion.DESTINY2) {
      if (!this.account.lastMonth || !this.account.seasons) {
        this.$wait.start(this.detailKey)
      }

      if (!this.account.lastMonth) {
        try {
          await this.$store.dispatch('accounts/loadActivities', this.account)
        } catch (_) {}
      }

      if (!this.account.seasons) {
        try {
          await this.$store.dispatch('accounts/loadSeasons', this.account)
        } catch (_) {}
      }

      this.$wait.end(this.detailKey)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

hr {
  margin: 0 0 1em;
  background-color: #c5c5c5;
}

.more {
  display: block;
  height: 100%;
  padding: 1.25em 1em 1em;
  border-radius: 0 0 6px 6px;
  margin-top: -6px;
  background: #d0d0d0;
}

.more /deep/ .activities + .seasons {
  margin-top: calc(-1em + 2px);
}

.more .level {
  margin-bottom: 1em;
  display: grid;
  grid-template-columns: 50% 50%;
}

.more .last-seen {
  max-width: 210px;
  word-break: break-word;
}

.more .last-seen,
.more .triumph {
  flex-direction: column-reverse;
  align-items: flex-start;
}

.more .last-seen small,
.more .triumph small {
  font-size: 13px;
  line-height: 1;
}

.more .triumph span {
  font-size: 26px;
  line-height: 1;
  font-weight: 700;
}

.more .triumph .over {
  font-size: 12px;
  line-height: 1;
  font-weight: 300;
}

.more .triumph .over-icon {
  height: 11px;
  fill: #888888;
}

.more .last-seen span {
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
}

.skeleton {
  height: 100px;
  position: relative;
  background: #bbbbbb;
  margin: 0 -1em 1em;
}

.bungie-link {
  width: 226px;
  height: 32px;
  display: block;
  border-radius: 16px;
  // background-image: linear-gradient(to right, #6c82e9, #70489a);
  background-image: linear-gradient(163deg, $global, $globalGradient 99%);
  box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.35);
  color: #ffffff;
  padding: 0.25em;
  text-align: center;
  font-size: 14px;
  line-height: 26px;
  margin: 1rem auto;
}

.clan {
  color: inherit;
  padding: 1em;
  text-align: center;
  display: flex;
  margin: -1em -1em 0;
  align-items: center;
}

.clan > span {
  flex-grow: 1;
}

.clan > span > strong {
  padding-right: 0.5em;
}
</style>
