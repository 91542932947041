<template>
  <footer>
    <ad-block />
    <p class="credit">
      <span class="credit-section">
        <a @click="loadAccount" class="author">{{ $t('footer.copyright') }} &copy; François Allard {{ year }}</a>
      </span>
      <span class="sep no-mobile"> | </span>
      <span class="credit-section">
        <a class="donate"
          href="http://www.patreon.com/wastedondestiny"
          target="_blank">
          <i18n path="footer.donate">
            <font-awesome :icon="[ 'far', 'heart' ]"/>
          </i18n>
        </a>
      </span>
      <span class="sep no-mobile"> | </span>
      <span class="credit-section">
        <a class="discord"
          href="https://dsc.gg/wastedondestiny"
          target="_blank">
          <i18n path="footer.discord">
            <font-awesome :icon="[ 'fab', 'discord' ]"/>
          </i18n>
        </a>
        <span class="sep"> | </span>
        <a href="https://www.nmedia.ca/en-CA/" target="_blank">
          <i18n path="footer.design">
            <nmedia-logo class="nmedia"/>
          </i18n>
        </a>
      </span>
      <span class="sep no-mobile"> | </span>
      <span class="credit-section">
        <a @click="activateAnimations">{{ animations ? 'Remove animations' : 'Activate animations?' }}</a>
      </span>
    </p>
  </footer>
</template>

<script>
import NmediaLogo from '../assets/nmedia.svg'
import AdBlock from './AdBlock'
import MembershipType from '../helpers/membershipType'

export default {
  components: {
    AdBlock,
    NmediaLogo
  },
  data () {
    return {
      year: new Date().getFullYear()
    }
  },
  computed: {
    animations () {
      return this.$store.getters['animations']
    }
  },
  methods: {
    async loadAccount () {
      await this.$store.dispatch('accounts/load', { membershipType: MembershipType.STEAM, membershipId: '4611686018467210709' })
    },
    activateAnimations () {
      this.$store.commit('activateAnimations')
    }
  }
}
</script>

<style scoped>
  footer {
    width: 100%;
    text-align: center;
  }

  footer a {
    color: #adadad;
  }

  footer a:hover {
    color: #ffffff;
  }

  .sponsor {
    height: 32px;
    margin-bottom: 0 !important;
  }

  .sponsor span {
    line-height: 1;
  }

  .credit {
    display: block;
    font-size: 14px;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .credit svg {
    vertical-align: middle;
  }

  .donate {
    color: #dedede;
  }

  .sep {
    color: #dedede;
  }

  @media screen and (max-width: 768px) {
    .credit-section {
      display: block;
    }

    .no-mobile {
      display: none;
    }
  }
</style>
