<template>
  <div class="container is-medium">
    <div class="container is-small">
      <clan-search-bar class="search" @search="searchClan" />
    </div>
    <template v-if="clanLoaded">
      <div class="clan">
        <span>
          <strong>{{ clan.name }}</strong>
          <span>{{ clan.motto }}</span>
          <small><font-awesome class="icon" icon="users" :title="$t('leaderboard.clanMembersInfo')" />{{ $t('leaderboard.clanMembers', [clan.accounts.length]) }}</small>
        </span>
        <p>{{ clan.about }}</p>
      </div>
      <div class="columns">
        <div class="column is-7-tablet">
          <leaderboard-card :game-version="2" :clan="clanId" />
        </div>
        <div class="column">
          <leaderboard-card :game-version="1" :clan="clanId" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LeaderboardCard from '../components/LeaderboardCard'
import ClanSearchBar from '../components/ClanSearchBar'

export default {
  components: {
    LeaderboardCard,
    ClanSearchBar
  },
  data () {
    return {
      clanLoaded: false
    }
  },
  computed: {
    clan () {
      return this.$store.getters['clan/get']
    },
    clanId () {
      return +this.$route.params.id
    }
  },
  mounted () {
    this.clanLoaded = false
    this.loadClan()
  },
  methods: {
    async loadClan () {
      if (this.clanId) {
        await this.$store.dispatch('clan/load', this.clanId)
        this.clanLoaded = true
      }
    },
    async searchClan (clanName) {
      this.clanLoaded = false
      await this.$store.dispatch('clan/load', clanName)
      this.$router.push(`/clan/${this.clan.id}`)
      this.clanLoaded = true
    }
  }
}
</script>

<style lang="scss" scoped>
.clan {
  color: inherit;
  background: #fff;
  padding: 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
}

.clan > span {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.clan > span > strong {
  font-size: 24px;
  font-weight: 100;
}

.clan > span > small {
  display: flex;
  align-items: center;
  gap: 8px;
}

.clan > p {
  text-align: left;
  white-space: pre-wrap;
}

@media screen and (min-width: 769px) {
  .clan {
    flex-direction: row;
  }

  .clan > span {
    width: 40%;
  }
}
</style>
