function timeFormat (seconds) {
  let formattedTime = ''

  const minutes = Math.round(seconds / 60)
  const hours = Math.floor(minutes / 60)

  if (hours > 0) {
    formattedTime += hours + 'h '
  }

  if (minutes % 60 > 0) {
    formattedTime += minutes % 60 + 'm'
  }

  return formattedTime || 'N/A'
}

export default timeFormat
