<template>
  <a v-tooltip="{ content: text, trigger: 'hover click' }"><slot /></a>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

a.has-tooltip {
  color: $globalGradient;
}

a.has-tooltip:hover {
  color: $global;
}
</style>
