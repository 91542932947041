import router from '../router'

const url = router.match(window.location.pathname).name.startsWith('search') ? window.location.pathname.replace('/', '') : ''

const state = {
  url,
  data: [ ...new Set(url.split(',')) ]
}

const mutations = {
  refresh (state, data) {
    state.data = [ ...new Set(data.map(item => item.membershipId)) ]
    state.url = state.data.join(',')
    const newRoute = '/' + state.url
    if (router.currentRoute.path !== newRoute) router.replace(newRoute)
  }
}

const getters = {
  get (state) {
    return state.data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
