import Vue from 'vue'
import Router from 'vue-router'
import ClanPage from './views/ClanPage'
import SearchPage from './views/SearchPage'
import LeaderboardPage from './views/LeaderboardPage'
import AboutPage from './views/AboutPage'
import PatreonPage from './views/PatreonPage'
import NotFoundPage from './views/NotFoundPage'

const appName = 'Time Wasted on Destiny'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/leaderboard',
      name: 'leaderboard',
      component: LeaderboardPage,
      meta: {
        title: appName + ' - Leaderboard',
        layout: 'MainLayout'
      }
    },
    {
      path: '/about',
      name: 'about',
      component: AboutPage,
      meta: {
        title: appName + ' - About',
        layout: 'MainLayout'
      }
    },
    {
      path: '/patreon',
      name: 'patreon',
      component: PatreonPage,
      meta: {
        title: appName + ' - Patreon integration',
        layout: 'SimpleLayout'
      }
    },
    {
      path: '/clan',
      name: 'clan',
      component: ClanPage,
      meta: {
        title: appName + ' - Clan Search',
        layout: 'MainLayout'
      }
    },
    {
      path: '/clan/:id',
      name: 'clanResult',
      component: ClanPage,
      meta: {
        title: appName + ' - Clan Search',
        layout: 'MainLayout'
      }
    },
    {
      path: '/',
      name: 'search',
      component: SearchPage,
      meta: {
        title: appName,
        layout: 'MainLayout'
      }
    },
    {
      path: '/:id',
      name: 'searchResult',
      component: SearchPage,
      meta: {
        title: appName,
        layout: 'MainLayout'
      }
    },
    {
      path: '*',
      component: NotFoundPage,
      meta: {
        title: appName + ' - Not Found',
        layout: 'MainLayout'
      }
    }
  ]
})
