<template>
  <ins class="adsbygoogle"
       :data-ad-client="client"
       :data-ad-slot="adSlot"
       :data-ad-format="format"
       :data-full-width-responsive="responsive"></ins>
</template>

<script>
export default {
  props: {
    client: {
      type: String,
      required: true
    },
    adSlot: {
      type: String,
      required: true
    },
    format: {
      type: String,
      required: false,
      default: 'auto'
    },
    responsive: {
      type: String,
      required: false,
      default: 'true'
    }
  },
  mounted () {
    (window.adsbygoogle = window.adsbygoogle || []).push({})
  }
}
</script>

<style scoped>
  .adsbygoogle {
    z-index: 1;
  }
</style>
