<template>
  <div class="main-wrapper">
    <svg version="1.1" id="L1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100" xml:space="preserve" class="main-loader">
      <circle class="circle" stroke-width="0.5" cx="50" cy="50" r="47" />
      <circle class="circle" stroke-width="1" stroke-miterlimit="15" stroke-dasharray="15,14.75" cx="50" cy="50" r="47">
        <animateTransform attributeName="transform" type="rotate" dur="10s" from="0 50 50"
          to="360 50 50" repeatCount="indefinite" />
      </circle>

      <path class="circle" stroke-width="1" d="M 50 3 a 1 1 0 0 0 0 94">
        <animateTransform attributeName="transform" type="rotate" dur="10s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
      </path>

      <line x1="17" y1="83" x2="83" y2="17" stroke-width="0.25" class="line" />
      <line x1="83" y1="83" x2="17" y2="17" stroke-width="0.25" class="line" />

      <path class="fill" stroke-width="1.5" d="M 50 3 l -40 70 l 80 0 l -40 -70" />

      <circle class="circle" stroke-width="0.25" stroke-miterlimit="10" stroke-dasharray="10,9.75" cx="50" cy="50" r="38">
        <animateTransform attributeName="transform" type="rotate" dur="15s" from="360 50 50" to="0 50 50" repeatCount="indefinite" />
      </circle>

      <line x1="12" y1="50" x2="88" y2="50" stroke-width="0.25" class="line" />
      <line x1="50" y1="12" x2="50" y2="88" stroke-width="0.25" class="line" />

      <path class="fill" stroke-width="1.5" d="M 50 97 l -40 -70 l 80 0 l -40 70" />

      <path class="triangle" stroke-width="1.5" d="M 50 3 l -40 70 l 80 0 l -40 -70">
        <animateTransform attributeName="transform" type="rotate" dur="20s" from="-180 50 50" to="180 50 50" repeatCount="indefinite" />
      </path>

      <circle class="fill" cx="50" cy="50" r="15" />

      <line x1="37.5" y1="70" x2="62.5" y2="30" stroke-width="0.5" class="line" />
      <line x1="62.5" y1="70" x2="37.5" y2="70" stroke-width="0.5" class="line" />
      <line x1="62.5" y1="70" x2="37.5" y2="30" stroke-width="0.5" class="line" />
      <line x1="62.5" y1="30" x2="37.5" y2="30" stroke-width="0.5" class="line" />

      <template v-if="progress !== undefined">
        <circle class="loading-bar" r="49" cx="50" cy="50" :stroke-dasharray="tProgress" :stroke-dashoffset="rProgress" stroke-width="2" />
      </template>
      <template v-else>
        <circle class="loading-bar" r="49" cx="50" cy="50" :stroke-dasharray="tProgress" :stroke-dashoffset="hProgress" stroke-width="2">
          <animateTransform attributeName="transform" type="rotate" dur="10s" from="360 50 50" to="0 50 50" repeatCount="indefinite" />
        </circle>
      </template>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: false,
      validator: val => val === undefined || (val >= 0 && val <= 100)
    }
  },
  data () {
    return {
      tProgress: Math.PI * 2 * 49 + 'px',
      hProgress: Math.PI * 49 + 'px'
    }
  },
  computed: {
    rProgress () {
      return (Math.PI * 2 * 49 * ((100 - this.progress) / 100)) + 'px'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

.main-wrapper {
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.75;
  padding-top: 40px;
}

.main-loader {
  height: 250px;
}

.circle {
  stroke: #fff;
}

.fill {
  fill: $global;
  opacity: 0.5;
}

.line {
  stroke: #fff;
}

.triangle {
  stroke: #fff;
}

.loading-bar {
  fill: none;
  stroke: #fff;
}
</style>
