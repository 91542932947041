<template>
  <div class="header" :class="{ required }">
    <slot />
    <span class="right">
      <slot name="right" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

.header {
  width: 100%;
  height: 25px;
  position: relative;
  background: linear-gradient(270deg, $global 0, $globalGradient);
  color: #ffffff;
  line-height: 1;
  font-size: 16px;
  padding: 6px 12px;

  .right {
    position: absolute;
    right: 12px;
    color: #ffffff;
    line-height: 1;
    font-size: 9px;
    text-transform: uppercase;
    padding: 2px;
  }
}
</style>
