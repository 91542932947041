<template>
  <button class="button" @click="click" :class="{ disabled }">
    <slot />
  </button>
</template>

<script>
export default {
  methods: {
    click () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

.button {
  border: 0;
  min-width: 100px;
  height: 35px;
  border-radius: 18px;
  background: linear-gradient(270deg, $global 0, $globalGradient);
  font-size: 14px;
  font-weight: 500;
  padding: 0 1em;
  text-align: center;
  color: #ffffff;
}

.button:hover,
.button:active,
.button:focus {
  color: #ffffff !important;
}

.button:hover {
  background: linear-gradient(270deg, $globalLight 0, $globalGradientLight);
}

.button.disabled {
  background: #2e2e2e none !important;
  color: #888888 !important;
  cursor: default;
}
</style>
