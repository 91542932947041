<template>
  <div class="seasons" v-if="legacy && account.gameVersion === 2">
    <h3 class="section-title">{{ $t('account.seasonRanksTitle') }}</h3>
    <div class="season wide" v-if="legacy.rank !== '-'">
      <dt>{{ legacy.rank }}
        <the-popover :text="$t('account.legacyRankInfo')">&nbsp;<font-awesome icon="question-circle" /></the-popover>
      </dt>
      <dd>{{ $t('account.rankLegacy') }}</dd>
    </div>
    <template v-for="year in 4">
      <div class="year" :key="year" v-if="yearHasData(year + 2)" :data-year="year + 2">
        <span class="indicator">{{ $t('account.seasonYear', [year + 2]) }}</span>
        <div class="season" :class="{ 'is-last': isLastSeason(season.id) }" v-for="season in yearSeasons(year + 2)" :key="season.name">
          <dt>{{ season.rank }}</dt>
          <dd>{{ season.name }}</dd>
        </div>
        <div class="season" v-for="season in 4 - yearSeasons(year + 2).length" :key="season">
          <span class="filler">-</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ThePopover from './ThePopover.vue'
import Account from '../models/account'

export default {
  components: {
    ThePopover
  },
  props: {
    account: {
      type: Account,
      required: true
    }
  },
  computed: {
    seasons () {
      const items = []
      let year = 1

      for (const season of this.account.seasons) {
        if (season.name !== 'Legacy') {
          switch (season.id) {
            case 8: case 9: case 10: case 11:
              year = 3
              break
            case 12: case 13: case 14: case 15:
              year = 4
              break
            case 16: case 17: case 18: case 19:
              year = 5
              break
            case 20: case 21: case 22: case 23:
              year = 6
              break
            case 24: case 25: case 26: case 27:
              year = 7
              break
            default:
              year = 1
              break
          }

          items.push({
            id: season.id,
            year,
            name: season.name.toUpperCase(),
            rank: season.rank
          })
        }
      }

      return items.sort((a, b) => a.id - b.id)
    },
    legacy () {
      return this.account.seasons?.find(x => x.name === 'Legacy')
    }
  },
  methods: {
    yearSeasons (year) {
      return this.seasons.filter(x => x.year === year)
    },
    yearHasData (year) {
      return this.yearSeasons(year).some(x => x.rank !== '-')
    },
    isLastSeason (seasonNumber) {
      return Math.max(...[...this.seasons?.map(x => x.id), 0, 0]) === seasonNumber
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/variables.scss';

  .seasons {
    margin: 0 -1em 1em;
    padding: 1em;
    background: #bbbbbb;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em 0.25em;
  }

  .section-title {
    font-size: 10px;
    margin-bottom: 0.75em;
    margin-top: -0.75em;
    text-align: center;
    width: 100%;
  }

  .season {
    flex: 25%;
    text-align: center;
  }

  .season.wide {
    flex: 100%;
  }

  .year {
    flex: 100%;
    display: flex;
    gap: 0.5em 0.25em;
  }

  .year .indicator {
    content: 'Y' attr(data-year);
    font-size: 13px;
    align-self: center;
  }

  .season.is-last dt::before {
    content: '▸ ';
  }

  .season.is-last dt::after {
    content: ' ◂';
  }

  .season dd {
    font-size: 10px;
  }

  .season dt {
    font-weight: 500;
    line-height: 1;
  }

  .season .filler {
    font-size: 32px;
    line-height: 0;
    height: 100%;
    display: grid;
    place-items: center;
  }
</style>
