<template>
  <div class="breakdown" v-if="account.gameVersion === 2 && totalTime > 0">
    <h3 class="section-title">{{ $t('account.activitiesTitle') }}</h3>
    <div class="progress">
      <div class="progress-inner story" :style="{ width: storyPercent }"></div>
      <div class="progress-inner strikes" :style="{ width: strikePercent }"></div>
      <div class="progress-inner raid" :style="{ width: raidPercent }"></div>
      <div class="progress-inner gambit" :style="{ width: gambitPercent }"></div>
      <div class="progress-inner crucible" :style="{ width: cruciblePercent }"></div>
    </div>
    <div class="detail">
      <p>
        <strong>{{ storyTime }}</strong>
        <span>{{ $t('account.story') }}</span>
      </p>
      <p>
        <strong>{{ strikeTime }}</strong>
        <span>{{$t('account.strike')}}</span>
      </p>
      <p>
        <strong>{{ raidTime }}</strong>
        <span>{{$t('account.raid')}}</span>
      </p>
      <p>
        <strong>{{ gambitTime }}</strong>
        <span>{{$t('account.gambit')}}</span>
      </p>
      <p>
        <strong>{{ crucibleTime }}</strong>
        <span>{{$t('account.crucible')}}</span>
      </p>
    </div>
  </div>
</template>

<script>
import Account from '../models/account'
import percentageFormat from '../helpers/percentageFormat'

export default {
  computed: {
    totalTime () {
      return this.account.breakdown.story +
        this.account.breakdown.strike +
        this.account.breakdown.raid +
        this.account.breakdown.gambit +
        this.account.breakdown.crucible
    },
    storyTime () {
      return percentageFormat(this.account.breakdown.story, this.totalTime)
    },
    strikeTime () {
      return percentageFormat(this.account.breakdown.strike, this.totalTime)
    },
    raidTime () {
      return percentageFormat(this.account.breakdown.raid, this.totalTime)
    },
    gambitTime () {
      return percentageFormat(this.account.breakdown.gambit, this.totalTime)
    },
    crucibleTime () {
      return percentageFormat(this.account.breakdown.crucible, this.totalTime)
    },
    storyPercent () {
      return percentageFormat(this.account.breakdown.story, this.totalTime, false)
    },
    strikePercent () {
      return percentageFormat(this.account.breakdown.strike, this.totalTime, false)
    },
    raidPercent () {
      return percentageFormat(this.account.breakdown.raid, this.totalTime, false)
    },
    gambitPercent () {
      return percentageFormat(this.account.breakdown.gambit, this.totalTime, false)
    },
    cruciblePercent () {
      return percentageFormat(this.account.breakdown.crucible, this.totalTime, false)
    }
  },
  props: {
    account: {
      type: Account,
      required: true
    }
  }
}
</script>

<style>
  .breakdown {
    margin: 0 -1em 2px;
    padding: 1em;
    background: #bbbbbb;
  }

  .breakdown .progress {
    display: flex;
    height: 0.5em;
    margin-bottom: 0.25em !important;
  }

  .progress-inner {
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .section-title {
    font-size: 10px;
    margin-bottom: 0.75em;
    margin-top: -0.75em;
    text-align: center;
  }

  .story {
    background: #ed8129;
  }

  .strikes {
    background: #3d526f;
  }

  .raid {
    background: #461044;
  }

  .gambit{
    background: #47866f;
  }

  .crucible {
    background: #ca1c1b;
  }

  .detail {
    display: flex;
    font-size: 12px;
    justify-content: space-around;
  }

  .detail p {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
  }

  .detail span {
    font-size: 10px;
  }
</style>
