<template>
  <div class="character-card">
    <div class="background"></div>
    <div class="inner">
      <img alt="emblem" v-if="account.gameVersion === 1 && character.emblemImage" :src="character.emblemImage"/>
      <div class="inner-content"
           :style="{ backgroundImage: character.backgroundImage && 'url(' + character.backgroundImage + ')' }">
        <div class="left" :class="{ deleted: character.deleted }">
          <small>{{ $t(`character.${character.displayName}`) }}</small>
          <div v-if="account.guardianRank"><i>{{ account.guardianRank }}</i><strong>{{ $t(`account.guardianRank${account.guardianRank}`) }}</strong></div>
          <span>{{ character.timePlayed }}</span>
        </div>
        <div class="right" v-if="!character.deleted">
          <template v-if="account.gameVersion === 1">
            <span class="light"><power-icon />{{ character.power }}</span>
            <small>{{ $t('account.level', [character.level]) }}</small>
          </template>
          <template v-else>
            <span class="power"><power-icon />{{ character.power }}</span>
            <small v-if="character.level">{{ $t('account.seasonLevel', [character.level]) }}</small>
            <small v-else>&nbsp;</small>
          </template>
        </div>
      </div>
      <div v-if="character.title" class="supplement charTitle" :class="{ gilded: character.gilded }">
        <span>{{ character.title }}
          <template v-if="character.gilded || character.gildedCount">
            <gilded-icon />
            <sup v-if="character.gildedCount > 1">{{ character.gildedCount }}</sup>
          </template>
        </span>
      </div>
      <div v-if="account.gameVersion === 2 && !character.deleted && character.legend" class="supplement duo">
        <span>
          <small>{{ $t('account.artifactLevel') }}
            <the-popover :text="$t('account.artifactLevelInfo')">&nbsp;<font-awesome icon="question-circle" /></the-popover>
          </small>
          <span class="artifactPower"><power-icon />{{ character.legend }}</span>
        </span>
        <span>
          <small>{{ $t('account.timeAfk') }}
            <the-popover :text="$t('account.timeAfkInfo')">&nbsp;<font-awesome icon="question-circle" /></the-popover>
          </small>
          <span>{{ character.timeAfk }}</span>
        </span>
      </div>
      <div v-else-if="account.gameVersion === 2 && !character.deleted && character.timeAfk" class="supplement">
        <span>
          <small>{{ $t('account.timeAfk') }}
            <the-popover :text="$t('account.timeAfkInfo')">&nbsp;<font-awesome icon="question-circle" /></the-popover>
          </small>
          <span>{{ character.timeAfk }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ThePopover from './ThePopover'
import Character from '../models/character'
import Account from '../models/account'
import PowerIcon from '../assets/power.svg'
import GildedIcon from '../assets/gilded.svg'

export default {
  components: {
    ThePopover,
    PowerIcon,
    GildedIcon
  },
  props: {
    account: {
      type: Account,
      required: true
    },
    character: {
      type: Character,
      required: true
    }
  }
}
</script>

<style scoped>
  .character-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  .character-card .background {
    height: 14px;
    width: calc(100% - 32px);
    background-color: #c5c5c5;
    position: absolute;
  }

  .character-card .inner {
    width: 300px;
    color: #fff;
    position: relative;
    left: 0;
  }

  .character-card .inner img {
    height: 61px;
    width: 61px;
    position: absolute;
    z-index: 2;
  }

  .character-card .inner-content {
    width: 100%;
    height: 61px;
    display: flex;
    align-items: center;
    background-color: #222;
    background-size: cover;
    justify-content: space-between;
    padding: 0.5em 0.5em 0.5em 65px;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 1);
    z-index: 1;
  }

  .left {
    padding-top: 2px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    font-size: 16px;
    justify-content: center;
  }

  .left span {
    font-size: 18px;
    font-weight: 500;
  }

  .left div {
    margin-top: -2px;
    margin-bottom: 2px;
    height: 12px;
    font-size: 12px;
    line-height: 0;
  }

  .left div > i {
    display: inline-grid;
    width: 16px;
    height: 16px;
    place-items: center;
    background: #eee;
    color: #000;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    mix-blend-mode: screen;
  }

  .left div > strong {
    margin-left: 4px;
    color: #eee;
    font-weight: normal;
  }

  .deleted span {
    color: #ff4738;
  }

  .right {
    text-align: right;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 15px;
  }

  .right span {
    font-size: 18px;
    font-weight: 500;
  }

  .light {
    color: #e2d259;
  }

  .light svg {
    height: 12px;
    fill: #e2d259;
    margin-right: 0.125em;
  }

  .power {
    color: #e2d259;
  }

  .power svg {
    height: 12px;
    fill: #e2d259;
    margin-right: 0.125em;
  }

  .artifactPower {
    color: #09d7d0;
  }

  .artifactPower svg {
    height: 10px;
    fill: #09d7d0;
    margin-right: 0.125em;
  }

  .supplement {
    background: #111;
    justify-content: space-around;
    align-items: center;
  }

  .supplement.trio {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
  }

  .supplement.duo {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .supplement.charTitle {
    padding: 4px;
  }

  .supplement.charTitle > span {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #ba69c7;
    padding: 4px;
    border-top: 2px solid #ba69c7;
    border-bottom: 2px solid #ba69c7;
  }

  .supplement.charTitle.gilded > span {
    color: #cca266;
    border-top: 2px solid #cca266;
    border-bottom: 2px solid #cca266;
  }

  .supplement.charTitle svg {
    height: 16px;
    display: inline;
    vertical-align: middle;
    fill: currentColor;
  }

.supplement.charTitle sup {
  font-size: 10px;
  line-height: 0;
}

  .supplement > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
  }

  .supplement > span > small {
    font-size: 12px;
  }

  .supplement > span > span {
    font-size: 15px;
    font-weight: bold;
  }
</style>
