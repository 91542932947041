const MembershipTypeName = Object.freeze({
  XBOX: 'xbox',
  PLAYSTATION: 'playstation',
  STEAM: 'steam',
  STADIA: 'stadia',
  EPIC: 'epic',
  UNKNOWN: ''
})

export default MembershipTypeName
