import check from 'check-types'
import timeFormat from '../helpers/timeFormat'
import numberFormat from '../helpers/numberFormat'

class Entry {
  constructor () {
    this.membershipId = ''
    this.membershipType = 0
    this.gameVersion = 0
    this.displayName = ''
    this.value = 0
    this.rank = 0
  }

  get id () {
    return this.gameVersion + '.' + this.membershipType + '.' + this.membershipId
  }

  get time () {
    return timeFormat(this.value)
  }

  get number () {
    return numberFormat(this.value)
  }

  static fromJson (json) {
    validateJsonFormat(json)

    const entry = new Entry()
    entry.membershipId = json.membershipId
    entry.membershipType = json.membershipType
    entry.gameVersion = json.gameVersion
    entry.displayName = json.displayName
    entry.value = json.value
    return entry
  }
}

function validateJsonFormat (json) {
  if (check.not.object(json)) throw new Error('JSON must be an object')
  if (check.not.string(json['membershipId'])) throw new Error('MembershipId must be a string')
  if (check.not.number(json['membershipType'])) throw new Error('MembershipType must be a number')
  if (check.not.number(json['gameVersion'])) throw new Error('GameVersion must be an number')
  if (check.not.string(json['displayName'])) throw new Error('DisplayName must be a string')
  if (check.not.number(json['value']) && check.not.null(json['value'])) throw new Error('Value must be a number')
}

export default Entry
