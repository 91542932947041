<template>
  <div class="container is-medium">
    <div class="container is-small">
      <search-bar class="search" />
    </div>
    <div class="content has-text-light">
      <h2 class="has-text-light">About</h2>
      <p>Time Wasted on Destiny is a website that tells you how much time you've spent playing the Destiny series.
        On top of this, it is the only website that tells you just how much time you've spent on deleted characters, which you could consider wasted time.</p>
      <h3 class="has-text-light">How to use</h3>
      <p>Using Time Wasted on Destiny is very simple, just enter your Bungie Username (Guardian#1234) into the search box.</p>
      <h3 class="has-text-light">FAQ</h3>
      <dl>
        <dt>What does the time played represent?</dt>
        <dd>The time played sums all of the time you spent playing activities. Social spaces and orbit times are excluded.</dd>
        <dt>Why is my username not working?</dt>
        <dd>Make sure you typed your Bungie Username (Guardian#1234) correctly. If you still experience problems, please submit an issue <a href="https://gitlab.com/groups/wastedondestiny/-/issues" target="_blank">here</a>.</dd>
        <dt>Why is another player showing up when I'm searching for my username?</dt>
        <dd>It is possible that another player uses the same name as you, but with a different number. Make sure to search with the full name (Guardian#1234). If you simply don't see your account there, make sure you typed your username correctly.</dd>
      </dl>
      <h3 class="has-text-light">Developers</h3>
      <p>There is an API accessible here: <a href="https://v2.api.wastedondestiny.com/docs" target="_blank">Documentation</a>.</p>
      <h3 class="has-text-light">Disclaimer</h3>
      <p>Time Wasted on Destiny is not affiliated with <a href="https://bungie.net" target="_blank">Bungie</a>.
        Destiny is a registered trademark of Bungie.</p>
      <h3 class="has-text-light" v-if="supporters.length">Supporters</h3>
      <p v-if="supporters.length">Time Wasted on Destiny could not survive without the support of Guardians like you. Here are the top supporters on <a href="http://www.patreon.com/wastedondestiny" target="_blank">Patreon</a>:</p>
      <h4 class="has-text-light" v-if="grandmaster.length">Grandmaster Donators</h4>
      <ul v-if="grandmaster.length">
        <li v-for="(supporter, i) in grandmaster" :key="i">
          <a href="#" v-if="supporter.membershipId" @click.stop.prevent="loadAccount(supporter)">{{ supporter.name }}</a>
          <span v-else>{{ supporter.name }}</span>
        </li>
      </ul>
      <h4 class="has-text-light" v-if="master.length">Master Donators</h4>
      <ul v-if="master.length">
        <li v-for="(supporter, i) in master" :key="i">
          <a href="#" v-if="supporter.membershipId" @click.stop.prevent="loadAccount(supporter)">{{ supporter.name }}</a>
          <span v-else>{{ supporter.name }}</span>
        </li>
      </ul>
      <h4 class="has-text-light" v-if="legend.length">Legend Donators</h4>
      <ul v-if="legend.length">
        <li v-for="(supporter, i) in legend" :key="i">
          <a href="#" v-if="supporter.membershipId" @click.stop.prevent="loadAccount(supporter)">{{ supporter.name }}</a>
          <span v-else>{{ supporter.name }}</span>
        </li>
      </ul>
      <h4 class="has-text-light" v-if="hero.length">Hero Donators</h4>
      <ul v-if="hero.length">
        <li v-for="(supporter, i) in hero" :key="i">
          <a href="#" v-if="supporter.membershipId" @click.stop.prevent="loadAccount(supporter)">{{ supporter.name }}</a>
          <span v-else>{{ supporter.name }}</span>
        </li>
      </ul>
      <h4 class="has-text-light" v-if="adept.length">Adept Donators</h4>
      <ul v-if="adept.length">
        <li v-for="(supporter, i) in adept" :key="i">
          <a href="#" v-if="supporter.membershipId" @click.stop.prevent="loadAccount(supporter)">{{ supporter.name }}</a>
          <span v-else>{{ supporter.name }}</span>
        </li>
      </ul>
      <h3 class="has-text-light">Bottom line</h3>
      <p>Thank you very much for using my website. If you like it, please <a href="https://www.patreon.com/wastedondestiny" target="_blank">subscribe to my Patreon!</a>
        If you are on Android, don't miss out on the <a href="https://play.google.com/store/apps/details?id=com.wastedondestiny.timewastedondestiny" target="_blank">app!</a>
        Here are some of the people without whom this website would not exist:</p>
      <ul>
        <li><a href="https://bungie-net.github.io/" target="_blank">Bungie who made their API accessible</a></li>
        <li><a href="https://destinydevs.github.io/BungieNetPlatform/" target="_blank">lowlines for his awesome documentation</a></li>
        <li><a href="https://www.reddit.com/r/DestinyTheGame/" target="_blank">/r/DestinyTheGame for supporting and sharing my project</a></li>
        <li><a href="https://www.nmedia.ca/en-CA/" target="_blank">Nmedia for the design</a></li>
        <li><a href="#" @click.stop.prevent="loadAccount(null)">Aderaen#6423 for the original idea</a></li>
        <li>All my friends and supporters for helping me crushing bugs and test the website</li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import SearchBar from '../components/SearchBar'

export default {
  components: {
    SearchBar
  },
  data () {
    return {
      supporters: []
    }
  },
  computed: {
    grandmaster () {
      return this.supporters.filter(x => x.tier === 'Grandmaster Donator')
    },
    master () {
      return this.supporters.filter(x => x.tier === 'Master Donator')
    },
    legend () {
      return this.supporters.filter(x => x.tier === 'Legend Donator')
    },
    hero () {
      return this.supporters.filter(x => x.tier === 'Hero Donator')
    },
    adept () {
      return this.supporters.filter(x => x.tier === 'Adept Donator')
    }
  },
  methods: {
    async loadAccount (account = null) {
      if (account) {
        await this.$store.dispatch('accounts/load', account)
        return
      }

      await this.$store.dispatch('accounts/load', { membershipId: '4611686018467654239' })
    }
  },
  async mounted () {
    try {
      const supporters = await axios.get(`${process.env.VUE_APP_API}/supporters`)

      if (supporters?.data?.length) {
        this.supporters = supporters.data
      }
    } catch (error) { }
  }
}
</script>

<style scoped>
dt {
  font-size: 18px;
  font-weight: 500;
}

dd:not(:last-of-type) {
  margin-bottom: 1em;
}

p, dd {
  font-size: 14px;
  color: #ccc;
}

li {
  font-size: 14px;
}

a {
  color: #fff;
  text-decoration: underline;
}
</style>
