import axios from 'axios'
import Vue from 'vue'
import { formatUsername } from '../helpers/displayName'

const state = {
  clan: null
}

const actions = {
  async load (context, name) {
    if (!name) return

    context.commit('loading', true, { root: true })
    const accounts = []

    try {
      const clan = await axios.get(`${process.env.VUE_APP_API}/clan/${name}`)

      if (!clan.data) {
        Vue.toasted.show(`No clan found for ${formatUsername(name)}, please try again later.`, {
          theme: 'toasted-primary',
          position: 'bottom-center'
        })
      }

      context.commit('set', clan.data)
      let count = 0
      const total = clan.data.accounts.length * 2

      for (const { membershipType, membershipId } of clan.data.accounts) {
        accounts.push(
          axios.get(
            `${process.env.VUE_APP_API}/character/1/${membershipType}/${membershipId}/?language=${localStorage.getItem('locale') || 'en'}`
          ).finally(() => {
            context.commit('loading', (++count / total) * 100, { root: true })
          })
        )
        accounts.push(
          axios.get(
            `${process.env.VUE_APP_API}/character/2/${membershipType}/${membershipId}/?language=${localStorage.getItem('locale') || 'en'}`
          ).finally(() => {
            context.commit('loading', (++count / total) * 100, { root: true })
          })
        )
      }
    } catch (error) {
      console.error(error)
      Vue.toasted.show(`Could not load clan ${formatUsername(name)}, please try again later.`, {
        theme: 'toasted-primary',
        position: 'bottom-center'
      })
    }

    await Promise.allSettled(accounts)
    context.commit('loading', false, { root: true })
  }
}

const mutations = {
  set (state, clan) {
    state.clan = clan
  }
}

const getters = {
  get (state) {
    return state.clan
  }
}

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters
}
