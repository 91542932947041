export const cleanUsername = username => {
  return username
    .replace(/\?/g, '%253F')
    .replace(/#/g, '%2523')
    .replace(/\//g, '%252F')
}

export const formatUsername = username => {
  return username
    .replace(/%25/g, '%')
    .replace(/%3F/g, '?')
    .replace(/%23/g, '#')
    .replace(/%2F/g, '/')
}
