<template>
  <div class="dialog-window" @click.self="close">
    <div class="dialog-wrapper">
      <img class="dialog-image" :src="_image" />
      <div class="dialog-inner">
        <div class="dialog-title">
          <a class="dialog-close" href="#" @click.prevent="close" :title="$t('global.close')">
            <font-awesome :icon="[ 'fas', 'times' ]"/>
          </a>
          {{ title }}
        </div>
        <div class="dialog-text">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImage from '@/assets/social_media/witch_queen/logo_square.png'

export default {
  props: {
    title: {
      type: String
    },
    image: {
      type: [String, Object]
    }
  },
  computed: {
    _image () {
      return this.image?.type === 'Buffer'
        ? `data:image/jpeg;base64,${Buffer.from(this.image.data).toString('base64')}`
        : defaultImage
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.dialog-window {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
}

.dialog-wrapper {
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  display: flex;
  align-items: stretch;
  max-height: 80vh;
  overflow-y: auto;
}

.dialog-image {
  max-width: 30%;
  object-fit: cover;
}

.dialog-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.dialog-title {
  width: 100%;
  color: #ffffff;
  font-size: 32px;
  padding: 20px 24px;
  background: rgba(255, 255, 255, 0.25);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 8px;
}

.dialog-close {
  color: #ffffff;
  float: right;
}

.dialog-text {
  width: 100%;
  max-width: 70%;
  padding: 20px 24px;
  color: #dddddd;
  font-size: 18px;
  font-weight: 300;
  flex: 1;
}

.dialog-text >>> p {
  padding-bottom: 20px;
}

.dialog-text >>> a {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
}

.dialog-text >>> a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .dialog-image {
    display: none;
  }

  .dialog-title {
    padding: 16px;
    font-size: 28px;
    letter-spacing: 6px;
  }

  .dialog-text {
    max-width: 100%;
    padding: 16px;
  }
}
</style>
