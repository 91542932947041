import check from 'check-types'
import MembershipType from '../helpers/membershipType'
import MembershipTypeName from '../helpers/membershipTypeName'
import timeFormat from '../helpers/timeFormat'
import { formatDistance } from 'date-fns'
import * as locales from 'date-fns/locale'

class Account {
  constructor () {
    this.membershipId = ''
    this.membershipType = 0
    this.gameVersion = 0
    this.displayName = ''
    this.isBungieName = false
    this.crossSaveActive = false
    this.dateLastPlayed = ''
    this.currentlyPlaying = ''
    this.characters = []
    this.rank = {
      isTop: false,
      value: 0
    }
    this.lastMonth = null
    this.breakdown = {
      story: 0,
      strike: 0,
      raid: 0,
      gambit: 0,
      crucible: 0
    }
    this.seasons = null
    this.bungieAccount = null
    this.score = 0
    this.legacyScore = 0
    this.maxScore = 0
    this.seals = 0
    this.clan = null
  }

  get activeCharacters () {
    return this.characters.filter(item => !item.deleted)
  }

  get id () {
    return this.gameVersion + '.' + this.membershipType + '.' + this.membershipId
  }

  get membershipTypeName () {
    switch (this.membershipType) {
      case MembershipType.XBOX:
        return MembershipTypeName.XBOX
      case MembershipType.PLAYSTATION:
        return MembershipTypeName.PLAYSTATION
      case MembershipType.STEAM:
        return MembershipTypeName.STEAM
      case MembershipType.STADIA:
        return MembershipTypeName.STADIA
      case MembershipType.EPIC:
        return MembershipTypeName.EPIC
      case MembershipType.UNKNOWN:
      default:
        return MembershipTypeName.UNKNOWN
    }
  }

  get realSeconds () {
    return this.activeCharacters.length ? Object.values(this.activeCharacters).reduce((a, item) => a + item.seconds, 0) : 0
  }

  get timePlayed () {
    return this.characters.length ? timeFormat(Object.values(this.characters).reduce((a, item) => a + item.seconds, 0)) : '0h'
  }

  get timeWasted () {
    const characters = this.characters.filter(character => character.deleted)
    return characters.length ? timeFormat(Object.values(characters).reduce((a, item) => a + item.seconds, 0)) : '0h'
  }

  static fromJson (json, locale) {
    validateJsonFormat(json)

    const account = new Account()
    account.membershipId = json.membershipId
    account.membershipType = json.membershipType
    account.gameVersion = json.gameVersion
    account.displayName = json.displayName
    account.isBungieName = json.isBungieName
    account.crossSaveActive = json.crossSaveActive
    account.currentlyPlaying = json.currentlyPlaying ? (json.currentlyPlaying + (json.currentlyPlayingMode ? (' // ' + json.currentlyPlayingMode) : '')) : null
    account.dateLastPlayed = json.dateLastPlayed ? formatDistance(new Date(json.dateLastPlayed), new Date(), { addSuffix: true, locale: locales[locale] }) : null
    account.guardianRank = json.guardianRank
    account.score = json.score
    account.legacyScore = json.legacyScore
    account.maxScore = json.maxScore
    account.seals = json.seals
    account.clan = json.clan

    return account
  }
}

function validateJsonFormat (json) {
  if (check.not.object(json)) throw new Error('JSON must be an object')
  if (check.not.string(json['membershipId'])) throw new Error('MembershipId must be a string')
  if (check.not.number(json['membershipType'])) throw new Error('MembershipType must be a number')
  if (check.not.string(json['displayName'])) throw new Error('DisplayName must be a string')
  if (check.not.boolean(json['isBungieName'])) throw new Error('IsBungieName must be a boolean')
  if (check.not.number(json['gameVersion'])) throw new Error('GameVersion must be an number')
  if (check.not.number(json['score'])) throw new Error('Score must be a number')
}

export default Account
