<template>
  <nav class="pagination is-centered" role="navigation" aria-label="pagination">
    <a class="pagination-previous"
       @click="onClickFirstPage"
       :disabled="isInFirstPage">
      <font-awesome icon="angle-double-left" />
    </a>
    <a class="pagination-previous"
       @click="onClickPreviousPage"
       :disabled="isInFirstPage">
      <font-awesome icon="angle-left" />
    </a>
    <a class="pagination-next"
       @click="onClickNextPage"
       :disabled="isInLastPage">
      <font-awesome icon="angle-right" />
    </a>
    <ul class="pagination-list">
      <li v-for="page in pages"
          :key="page.name">
        <a :disabled="page.active"
           :class="{ 'is-current': page.active }"
           @click="onClickPage(page.name)"
           class="pagination-link"
           :aria-label="'Goto' + page.name">
          {{ page.name }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  computed: {
    isInFirstPage () {
      return this.currentPage === 1
    },
    isInLastPage () {
      return this.totalPages === 0 || this.currentPage === this.totalPages
    },
    startPage () {
      if (this.currentPage === 1) {
        return 1
      }

      if (this.currentPage === this.totalPages) {
        return this.totalPages - (this.maxVisibleButtons - 1)
      }

      return this.currentPage - 1
    },
    pages () {
      const range = []

      if (this.totalPages === 0) {
        return [{
          name: 1,
          active: true
        }]
      }

      for (let i = this.startPage; i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages); i += 1) {
        range.push({
          name: i,
          active: i === this.currentPage
        })
      }

      return range
    }
  },
  methods: {
    onClickFirstPage () {
      if (!this.isInFirstPage) {
        this.$emit('pagechanged', 1)
      }
    },
    onClickPreviousPage () {
      if (!this.isInFirstPage) {
        this.$emit('pagechanged', this.currentPage - 1)
      }
    },
    onClickPage (page) {
      this.$emit('pagechanged', page)
    },
    onClickNextPage () {
      if (!this.isInLastPage) {
        this.$emit('pagechanged', this.currentPage + 1)
      }
    }
  },
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
    totalPages: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

.pagination {
  position: absolute;
  top: 330px;
  width: calc(100% + 8px);
  justify-content: space-between;
}

.pagination a,
.pagination-ellipsis {
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  color: $global;
  min-width: 1.75em;
  border-radius: 0;
  border-bottom: 2px solid transparent;
}

a.pagination-previous,
a.pagination-next,
.pagination-ellipsis {
  color: #3b3b3b;
}

.pagination .is-current {
  border-bottom-color: $globalLight;
}

.pagination .pagination-previous {
  order: 1;
}

.pagination .pagination-list {
  justify-content: center;
  order: 2;
  flex-grow: 1;
  flex-shrink: 1;
}

.pagination-list li {
  flex-grow: 0;
  flex-shrink: 0;
}

.pagination .pagination-next {
  order: 3;
}

.pagination-next, .pagination-previous {
  flex-grow: 0;
  flex-shrink: 0;
}
</style>
