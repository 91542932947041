<template>
  <div>
    <input ref="search" @keyup.enter="search" v-model="clanName" :placeholder="$t('search.clanPlaceholder')"/>
    <div class="icon-select">
      <font-awesome class="icon" icon="users"/>
    </div>
    <button @click.prevent="search" class="submit is-hidden-mobile">{{ $t('search.search') }}</button>
    <button @click.prevent="search" class="submit-mobile is-hidden-tablet">
      <font-awesome icon="search"/>
    </button>
    <small>{{ $t('search.clanSearch') }}</small>
  </div>
</template>

<script>
import debounce from '../helpers/debounce'
import { cleanUsername } from '../helpers/displayName'

export default {
  data () {
    return {
      clanName: ''
    }
  },
  directives: {
    debounce
  },
  methods: {
    search () {
      if (this.clanName !== '') {
        this.$refs.search.blur()
        const clanName = this.clanName
        this.clanName = ''
        this.$emit('search', cleanUsername(clanName))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

.search {
  margin-bottom: 3em;
  width: 100%;
  height: 60px;
  position: relative;
}

.icon-select {
  width: 60px;
  height: 60px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

.icon {
  width: 60px;
  height: 17px;
  color: #14171a;
}

.search input {
  border: none;
  height: 60px;
  width: 100%;
  font-family: Roboto, Arial, 'Helvetica Neue', sans-serif;
  font-size: 18px;
  color: #424242;
  border-radius: 30px;
  background: #ffffff none;
  padding: 10px 150px 10px 60px;
}

small {
  color: #fff;
  font-size: 12px;
  text-align: center;
  width: 100%;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .search input {
    padding-right: 60px;
  }
}

.submit, .submit-mobile {
  width: 150px;
  height: 60px;
  border-radius: 30px;
  position: absolute;
  text-align: center;
  top: 0;
  right: 0;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 4px;
  padding-left: 0.625rem;
  color: #ffffff;
  text-shadow: 2px 1px 6px rgba(0, 0, 0, 0.21);
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.6);
  background-image: linear-gradient(163deg, $global, $globalGradient 99%);
  border: solid 3px #ffffff;
  cursor: pointer;
}

.submit-mobile {
  width: 60px;
  padding: 0;
  font-size: 24px;
  font-weight: normal;
}

.submit:hover, .submit-mobile:hover {
  box-shadow: inset 0 0 150px 0 rgba(255, 255, 255, 0.2);
}

.submit:active, .submit-mobile:active {
  box-shadow: inset 0 0 150px 0 rgba(255, 255, 255, 0.8);
}
</style>
