import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Store from './store'
import i18n from './i18n'
import 'babel-polyfill'
import Vuex from 'vuex'
import Toasted from 'vue-toasted'
import VueGtag from 'vue-gtag'
import 'bulma/css/bulma.css'
import 'vue-toasted/dist/vue-toasted.min.css'
import VTooltip from 'v-tooltip'
import VueWait from 'vue-wait'
import './fontawesome'

Vue.use(Vuex)
Vue.use(VueWait)
Vue.use(Toasted)
Vue.use(VTooltip)

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  }

  to.meta.from = from

  next()
})

// noinspection JSUnresolvedVariable,ES6ModulesDependencies
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GTAG,
    params: {
      send_page_view: process.env.NODE_ENV === 'production'
    }
  }
}, router)

Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

Vue.config.productionTip = false

const store = Store()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  wait: new VueWait()
}).$mount('#app')
