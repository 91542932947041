<template>
  <div class="container is-medium">
    <div class="container is-small">
      <search-bar class="search" />
    </div>
    <div class="content has-text-light has-text-centered">
      <h2 class="has-text-light">This page does not exist</h2>
    </div>
  </div>
</template>

<script>
import SearchBar from '../components/SearchBar'

export default {
  components: {
    SearchBar
  }
}
</script>
