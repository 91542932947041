<template>
  <div class="background-animation">
    <background-lines class="background" />
    <div class="mask" />
    <sundial class="svg sundial" />
    <sundial-arms class="svg arms" />
    <particles-component id="particles" v-if="animations" :options="particlesOptions" />
    <div class="effect" />
    <div class="blur" />
    <div class="grid" :class="{ animated: animations }">
      <div v-for="n in 640" :key="n" />
    </div>
  </div>
</template>

<script>
import BackgroundLines from '../assets/background.svg'
import Sundial from '../assets/sundial.svg'
import SundialArms from '../assets/sundial_arms.svg'
import Trail from '../assets/trail.png'
import { ParticlesComponent } from 'particles.vue'
import particlesOptions from '../assets/particlesOptions.json'

particlesOptions.particles.shape.options = {
  images: {
    src: Trail,
    height: 1,
    width: 800
  }
}

export default {
  components: {
    BackgroundLines,
    Sundial,
    SundialArms,
    ParticlesComponent
  },
  data () {
    return {
      particlesOptions
    }
  },
  computed: {
    animations () {
      return this.$store.getters['animations']
    }
  },
  mounted () {
    const times = []
    let count = 100

    const refreshLoop = () => {
      window.requestAnimationFrame(() => {
        const now = performance.now()

        while (times.length > 0 && times[0] <= now - 1000) {
          times.shift()
        }

        times.push(now)

        if (times.length < 60 && --count <= 0 && this.animations) {
          this.$store.commit('activateAnimations')
          return
        }

        refreshLoop()
      })
    }

    refreshLoop()
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

.background-animation {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.svg {
  fill: none;
  position: absolute;
  z-index: -1;
  overflow: hidden;
  width: 90vh;
  height: 90vh;
  left: calc(50% - 45vh);
  top: 4vh;
  bottom: 0;
}

.background {
  fill: none;
  position: absolute;
  z-index: -1;
  overflow: hidden;
  width: 360vh;
  height: 360vh;
  left: calc(50% - 180vh);
  top: calc(50% - 178vh);
  bottom: 0;
  stroke: rgba(255, 255, 255, .25);
  stroke-width: .1;
  transform-origin: 50% 50%;
  animation: rotate-counter-clockwise 1500s linear infinite;
}

.mask {
  position: absolute;
  z-index: -1;
  top: -46vh;
  width: 196vh;
  height: 196vh;
  left: calc(50% - 98vh);
  background: radial-gradient($backgroundColor 35%, transparent 35%),
              repeating-radial-gradient(circle, $backgroundColor, $backgroundColor 10px, transparent 10px, transparent 15px);
}

.sundial {
  stroke: rgba(255, 255, 255, .1);
  animation: rotate-clockwise 300s linear infinite;
  transform-origin: 50% 53.5%;
}

.arms {
  stroke: rgba(255, 255, 255, .3);
  animation: rotate-counter-clockwise 150s linear infinite;
  transform-origin: 50% 53.5%;
}

.overlay {
  position: absolute;
  top: 12vh;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 84vh;
  opacity: .3;
  fill: $overlayColor;
}

.effect {
  position: absolute;
  top: -28vh;
  width: 100%;
  height: 100vh;
  background: url('../assets/effect.png') no-repeat center center;
  filter: blur(1px) opacity(.2);
  background-size: cover;
}

.blur {
  content: '';
  width: 100vw;
  height: 5px;
  background: #fff;
  box-shadow:
    0 0 8px #fff,
    0 0 16px #fff,
    0 0 24px #af62ff,
    0 0 32px #af62ff;
  position: absolute;
  z-index: 20;
  top: 67.5vh;
  filter: blur(1px);
}

.grid {
  width: 180%;
  height: 100%;
  background: #8000804d;
  display: grid;
  grid-template: repeat(16, 1fr) / repeat(40, 1fr);
  position: absolute;
  margin-top: 25vh;
  inset: 0 0 -23% -40%;
  transform: perspective(60vh) rotateX(75deg) translateY(-6.25vh);
}

.grid.animated {
  animation: gridz 1.8s infinite linear;
}

.grid div {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  filter: blur(1px);
}

@keyframes rotate-clockwise {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes rotate-counter-clockwise {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}

@keyframes gridz {
  100%{
    transform: perspective(60vh) rotateX(75deg) translateY(6.25vh);
  }
}

@media screen and (max-width: 768px) {
  .grid {
  grid-template: repeat(16, 1fr) / repeat(20, 1fr);
  }
}
</style>
