<template>
  <div class="hero is-fullheight main" :class="{ 'has-dialog': currentDialog }">
    <sundial-animation />
    <issue-warning />
    <the-header class="hero-head" :show-extra="showExtra" />
    <div class="hero-body">
      <keep-alive>
        <router-view :key="$route.path" />
      </keep-alive>
    </div>
    <the-footer class="hero-foot" />
    <template v-if="!loading && currentDialog">
      <dialog-window :title="currentDialog.title" :image="currentDialog.image" @close="closeDialog(currentDialog)">
        <span v-html="currentDialog.text" />
      </dialog-window>
    </template>
    <main-loader v-if="loading" :progress="progress" />
  </div>
</template>

<script>
import axios from 'axios'
import TheHeader from '../components/TheHeader'
import TheFooter from '../components/TheFooter'
import MainLoader from '../components/MainLoader'
import IssueWarning from '../components/IssueWarning'
import SundialAnimation from '../components/SundialAnimation'
import DialogWindow from '../components/DialogWindow'

export default {
  components: {
    IssueWarning,
    TheHeader,
    TheFooter,
    MainLoader,
    SundialAnimation,
    DialogWindow
  },
  data () {
    return {
      dialogs: [],
      currentDialog: null
    }
  },
  computed: {
    showExtra () {
      return this.$route.meta.showExtra !== undefined ? this.$route.meta.showExtra : true
    },
    loading () {
      return this.$store.getters['loading']
    },
    progress () {
      return this.$store.getters['progress']
    },
    unreadDialogs () {
      const readDialogIds = window.localStorage.getItem('readDialogIds') || []
      return this.dialogs.filter(x => !readDialogIds.includes(x.id)).sort((a, b) => a.createdOn - b.createdOn)
    }
  },
  async created () {
    try {
      const dialogs = await axios.get(`${process.env.VUE_APP_API}/dialogs`)

      if (dialogs?.data?.length) {
        this.dialogs = dialogs.data
        this.currentDialog = this.unreadDialogs[0]
      }
    } catch (error) {}
  },
  methods: {
    closeDialog (dialog) {
      this.dialogs = this.dialogs.filter(x => x.id !== dialog.id)
      this.currentDialog = this.unreadDialogs[0]
      const readDialogIds = window.localStorage.getItem('readDialogIds') || []
      window.localStorage.setItem('readDialogIds', [...readDialogIds, dialog.id])
    }
  }
}
</script>

<style lang="scss" scoped>
.has-dialog {
  overflow-y: hidden;
}
</style>
