function debounce (fn, delay) {
  let timeoutID = null

  return function () {
    clearTimeout(timeoutID)
    const args = arguments
    const that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

directive.debounce = debounce

function directive (el, binding) {
  if (binding.value !== binding.oldValue) {
    el.oninput = directive.debounce(function () {
      el.dispatchEvent(new Event('change'))
    }, parseInt(binding.value) || 500)
  }
}

export default directive
