<template>
  <div class="container is-medium">
    <div class="container is-small">
      <search-bar class="search" />
    </div>
    <div class="columns">
      <div class="column is-7-tablet">
        <leaderboard-card :game-version="2" />
      </div>
      <div class="column">
        <leaderboard-card :game-version="1" />
      </div>
    </div>
  </div>
</template>

<script>
import LeaderboardCard from '../components/LeaderboardCard'
import SearchBar from '../components/SearchBar'

export default {
  components: {
    LeaderboardCard,
    SearchBar
  }
}
</script>
