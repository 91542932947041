import check from 'check-types'
import Entry from './entry'

class Page {
  constructor () {
    this.count = 0
    this.page = 0
    this.players = []
    this.totalPlayers = 0
    this.totalPlayersPlatform = 0
  }

  static fromJson (json) {
    validateJsonFormat(json)

    const page = new Page()
    page.count = json.count
    page.page = json.page
    json.players.forEach(item => {
      const entry = Entry.fromJson(item)
      page.players.push(entry)
    })
    page.totalPlayers = json.totalPlayers
    page.totalPlayersPlatform = json.totalPlayersPlatform
    return page
  }
}

function validateJsonFormat (json) {
  if (check.not.object(json)) throw new Error('JSON must be an object')
  if (check.not.number(json['count'])) throw new Error('Count must be a number')
  if (check.not.number(json['page'])) throw new Error('Page must be a number')
  if (check.not.array(json['players'])) throw new Error('Players must be an array')
  if (check.not.number(json['totalPlayers'])) throw new Error('TotalPlayers must be a number')
  if (check.not.number(json['totalPlayersPlatform'])) throw new Error('TotalPlayersPlatform must be a number')
}

export default Page
