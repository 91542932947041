import axios from 'axios'
import Vue from 'vue'
import Character from '../models/character'
import Account from '../models/account'
import GameVersion from '../helpers/gameVersion'
import { formatUsername } from '../helpers/displayName'

const state = {
  accounts: [],
  searchResults: [],
  filters: []
}

const actions = {
  async loadFromUsername (context, { displayName, membershipType }) {
    context.commit('loading', true, { root: true })
    let json = null

    try {
      json = membershipType
        ? await axios.get(`${process.env.VUE_APP_API}/search/${membershipType}/${displayName}/?language=${localStorage.getItem('locale') || 'en'}`)
        : await axios.get(`${process.env.VUE_APP_API}/search/${displayName}/?language=${localStorage.getItem('locale') || 'en'}`)

      if (json.data.length > 0) {
        json.data.forEach(item => {
          const account = Account.fromJson(item, localStorage.getItem('locale') || 'en')
          context.commit('add', account)
        })
      } else {
        Vue.toasted.show(`No accounts found for ${formatUsername(displayName)}, please try again later.`, {
          theme: 'toasted-primary',
          position: 'bottom-center'
        })
      }
    } catch (error) {
      console.error(error)
      Vue.toasted.show(`Could not load account ${formatUsername(displayName)}, please try again later.`, {
        theme: 'toasted-primary',
        position: 'bottom-center'
      })
    }

    context.commit('url/refresh', context.state.accounts, { root: true })
    context.commit('loading', false, { root: true })
  },
  async loadFromUrl (context, url = []) {
    if (url.length && url[0] !== '') {
      context.commit('loading', true, { root: true })

      for (let membership of url) {
        const parts = membership.split('_')
        const membershipId = parts[1] || parts[0]

        try {
          const json = await axios.get(`${process.env.VUE_APP_API}/account/${membershipId}/?language=${localStorage.getItem('locale') || 'en'}`)

          if (json.data.length > 0) {
            json.data.forEach(item => {
              const account = Account.fromJson(item, localStorage.getItem('locale') || 'en')
              context.commit('add', account)
            })
          } else {
            Vue.toasted.show(`No accounts found for ${membershipId}, please try again later.`, {
              theme: 'toasted-primary',
              position: 'bottom-center'
            })
          }
        } catch (error) {
          console.error(error)
          Vue.toasted.show(`Could not load account ${membershipId}, please try again later.`, {
            theme: 'toasted-primary',
            position: 'bottom-center'
          })
        }
      }

      context.commit('url/refresh', context.state.accounts, { root: true })
      context.commit('loading', false, { root: true })
    }
  },
  async load (context, { membershipId }) {
    context.commit('loading', true, { root: true })
    try {
      const json = await axios.get(`${process.env.VUE_APP_API}/account/${membershipId}/?language=${localStorage.getItem('locale') || 'en'}`)

      if (json.data.length > 0) {
        json.data.forEach(item => {
          const account = Account.fromJson(item, localStorage.getItem('locale') || 'en')
          context.commit('add', account)
        })
      } else {
        Vue.toasted.show(`No accounts found for ${membershipId}, please try again later.`, {
          theme: 'toasted-primary',
          position: 'bottom-center'
        })
      }
    } catch (error) {
      console.error(error)
      Vue.toasted.show(`Could not load account ${membershipId}, please try again later.`, {
        theme: 'toasted-primary',
        position: 'bottom-center'
      })
    }

    context.commit('url/refresh', context.state.accounts, { root: true })
    context.commit('loading', false, { root: true })
  },
  async loadCharacters (context, account) {
    const json = await axios.get(`${process.env.VUE_APP_API}/character/${account.gameVersion}/${account.membershipType}/${account.membershipId}/?language=${localStorage.getItem('locale') || 'en'}`)

    if (json && json.data) {
      const characters = json.data.map(item => Character.fromJson(item))
      context.commit('setCharacters', [ characters, account.membershipType, account.membershipId, account.gameVersion ])

      if (characters.length) {
        context.dispatch('loadRank', account)
      }
    } else {
      throw new Error()
    }
  },
  async loadRank (context, account) {
    const rank = account.gameVersion === GameVersion.DESTINY2
      ? await axios.get(`${process.env.VUE_APP_API}/rank/${account.gameVersion}/-1/${account.membershipId}`)
      : await axios.get(`${process.env.VUE_APP_API}/rank/${account.gameVersion}/${account.membershipType}/${account.membershipId}`)
    context.commit('setRank', [ rank.data, account ])
  },
  async loadActivities (context, account) {
    const activities = await axios.get(`${process.env.VUE_APP_API}/activities/${account.membershipType}/${account.membershipId}`)
    context.commit('setActivities', [ activities.data.lastThirtyDays, account ])
    context.commit('setBreakdown', [ activities.data.breakdown, account ])
  },
  async loadSeasons (context, account) {
    const seasons = await axios.get(`${process.env.VUE_APP_API}/season/${account.membershipType}/${account.membershipId}/?language=${localStorage.getItem('locale') || 'en'}`)
    context.commit('setSeasons', [ seasons.data, account ])
  },
  remove (context, account) {
    context.commit('remove', account)
    context.commit('url/refresh', context.state.accounts, { root: true })
  }
}

const mutations = {
  toggle (state, filter) {
    const index = state.filters.indexOf(filter)

    if (index !== -1) {
      state.filters.splice(index, 1)
    } else {
      state.filters.push(filter)
    }
  },
  add (state, account) {
    const index = state.accounts.findIndex(item => item.membershipType === account.membershipType &&
      item.membershipId === account.membershipId &&
      item.gameVersion === account.gameVersion)

    if (index < 0) {
      state.accounts.push(account)
    }
  },
  remove (state, account) {
    const index = state.accounts.findIndex(item => item.membershipType === account.membershipType &&
      item.membershipId === account.membershipId &&
      item.gameVersion === account.gameVersion)

    if (index >= 0) {
      state.accounts.splice(index, 1)
    }
  },
  clear (state) {
    state.accounts = []
  },
  setSearchResults (state, results) {
    state.searchResults = results
  },
  setRank (state, [rank, account]) {
    const found = state.accounts.find(item => item.membershipType === account.membershipType &&
      item.membershipId === account.membershipId &&
      item.gameVersion === account.gameVersion)

    if (found) {
      found.rank = rank
    }
  },
  setActivities (state, [activities, account]) {
    const found = state.accounts.find(item => item.membershipType === account.membershipType &&
      item.membershipId === account.membershipId &&
      item.gameVersion === account.gameVersion)

    if (found) {
      found.lastMonth = activities
    }
  },
  setBreakdown (state, [breakdown, account]) {
    const found = state.accounts.find(item => item.membershipType === account.membershipType &&
      item.membershipId === account.membershipId &&
      item.gameVersion === account.gameVersion)

    if (found) {
      found.breakdown.story += breakdown.story || 0
      found.breakdown.strike += breakdown.strike || 0
      found.breakdown.raid += breakdown.raid || 0
      found.breakdown.gambit += breakdown.gambit || 0
      found.breakdown.crucible += breakdown.crucible || 0
    }
  },
  setSeasons (state, [seasons, account]) {
    const found = state.accounts.find(item => item.membershipType === account.membershipType &&
      item.membershipId === account.membershipId &&
      item.gameVersion === account.gameVersion)

    if (found) {
      found.seasons = seasons
    }
  },
  setCharacters (state, [characters, membershipType, membershipId, gameVersion]) {
    const found = state.accounts.find(item => item.membershipType === membershipType &&
      item.membershipId === membershipId &&
      item.gameVersion === gameVersion)

    if (found) {
      found.characters = characters
    }
  }
}

const getters = {
  get (state) {
    return state.accounts.filter(item =>
      state.filters.indexOf(item.membershipTypeName) === -1 &&
      state.filters.indexOf(item.gameVersion === 1 ? 'destiny' : 'destiny2') === -1)
  },
  count (state) {
    return state.accounts.length
  },
  filters (state) {
    return state.filters
  }
}

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters
}
