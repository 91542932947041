<template>
  <div class="container" :class="{ 'push-top': !empty }">
    <div class="container is-small">
      <div class="logo" v-if="empty">
        <main-logo />
        <p class="logo-explain" v-if="$i18n.locale !== 'en'">({{ $t('global.appName') }})</p>
      </div>
      <search-bar class="search" />
      <featured-block />
    </div>
    <div v-show="!empty">
      <div class="grid">
        <div id="grid">
          <account-card v-for="account in accounts" :key="account.id" :account="account" @refresh="refreshLayout" class="grid-element" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountCard from '../components/AccountCard'
import MainLogo from '../components/MainLogo'
import SearchBar from '../components/SearchBar'
import FeaturedBlock from '../components/FeaturedBlock'
import Macy from 'macy'

export default {
  components: {
    AccountCard,
    MainLogo,
    SearchBar,
    FeaturedBlock
  },
  computed: {
    accounts () {
      return this.$store.getters['accounts/get']
    },
    empty () {
      return this.$store.getters['accounts/count'] === 0
    }
  },
  data () {
    return {
      macy: null
    }
  },
  methods: {
    refreshLayout () {
      this.$nextTick(() => {
        this.macy.recalculate(true)
      })
    }
  },
  async created () {
    if (!this.$route.meta?.from?.name) {
      await this.$store.dispatch('accounts/loadFromUrl', this.$store.getters['url/get'])
    }
  },
  mounted () {
    this.macy = Macy({
      container: '#grid',
      margin: 16,
      columns: 2,
      breakAt: {
        1087: {
          columns: 1
        }
      }
    })
    this.refreshLayout()
  },
  watch: {
    accounts: {
      handler () {
        this.refreshLayout()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
#grid {
  margin: 0 auto;
  max-width: 470px;
}

.logo {
  margin: 0 0 1em;
}

.logo /deep/ .glitchedAnim {
  position: static;
  margin-top: -22.75vw;
}

.logo-explain {
  color: #fff;
  position: relative;
  top: -60px;
  height: 0;
  margin-left: 100px;
  letter-spacing: 2px;
}

@media screen and (min-width: 430px) {
  .logo /deep/ .glitchedAnim {
    position: static;
    margin-top: -22.125vw;
  }
}

@media screen and (min-width: 560px) {
  .logo /deep/ .glitchedAnim {
    position: static;
    margin-top: -7.625em;
  }
}

@media screen and (min-width: 769px) {
  .container:not(.push-top) {
    align-self: center;
  }

  .logo {
    margin: 0 -3rem 1em;
  }

  .logo /deep/ .glitchedAnim {
    position: static;
    margin-top: -9.95em;
  }

  .logo-explain {
    top: -65px;
    margin-left: 90px;
  }
}

@media screen and (min-width: 1088px) {
  #grid {
    max-width: unset;
    width: 940px;
  }

  .logo {
    margin: 0 -6rem 1em;
  }

  .logo /deep/ .glitchedAnim {
    position: static;
    margin-top: -12.25em;
  }

  .logo-explain {
    top: -80px;
    margin-left: 80px;
  }
}

.tile.is-parent {
  flex-wrap: wrap;
}
</style>
