import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faExternalLinkAlt,
  faRedo,
  faSearch,
  faPlus,
  faMinus,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faTrophy,
  faInfoCircle,
  faTimes,
  faQuestionCircle,
  faExclamationTriangle,
  faTrash,
  faCaretDown,
  faSort,
  faGlobe,
  faHourglassHalf,
  faShieldAlt,
  faBookmark,
  faUser,
  faUsers,
  faGamepad
} from '@fortawesome/free-solid-svg-icons'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import { faPaypal, faDiscord } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faExternalLinkAlt,
  faRedo,
  faSearch,
  faHeart,
  faPlus,
  faMinus,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faTrophy,
  faInfoCircle,
  faTimes,
  faPaypal,
  faQuestionCircle,
  faExclamationTriangle,
  faDiscord,
  faCaretDown,
  faSort,
  faGlobe,
  faHourglassHalf,
  faTrash,
  faBookmark,
  faShieldAlt,
  faUser,
  faUsers,
  faGamepad
)
Vue.component('FontAwesome', FontAwesomeIcon)
