<template>
  <div class="activities" v-if="max > 0 && account.gameVersion === 2">
    <h3 class="section-title">{{ $t('account.lastMonthTitle') }}</h3>
    <div class="max"><span>{{ formattedMax }}</span></div>
    <trend
      :data="activities"
      :gradient="colors"
      auto-draw
    />
    <div class="markers">
      <span>{{ startDate }}</span>
      <span>{{ endDate }}</span>
    </div>
  </div>
</template>

<script>
import Trend from 'vuetrend'
import Account from '../models/account'
import { format } from 'date-fns'
import * as locales from 'date-fns/locale'
import timeFormat from '../helpers/timeFormat'

export default {
  components: {
    Trend
  },
  computed: {
    startDate () {
      if (!this.account.lastMonth) {
        return ''
      }

      return format(new Date(Object.keys(this.account.lastMonth)[0]), 'PPP', { locale: locales[this.$i18n.locale] })
    },
    endDate () {
      if (!this.account.lastMonth) {
        return ''
      }

      const keys = Object.keys(this.account.lastMonth)
      return format(new Date(keys[keys.length - 1]), 'PPP', { locale: locales[this.$i18n.locale] })
    },
    activities () {
      if (!this.account.lastMonth) {
        return []
      }

      return Object.values(this.account.lastMonth)
    },
    max () {
      return Math.max(...this.activities)
    },
    formattedMax () {
      return timeFormat(this.max)
    },
    colors () {
      const style = getComputedStyle(document.querySelector('.submit')).backgroundImage
      return Array.from(style.matchAll(/(rgb\(\d+, \d+, \d+\))/g), x => x[0])
    }
  },
  props: {
    account: {
      type: Account,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/variables.scss';

  .activities {
    margin: 0 -1em 1em;
    padding: 1em;
    background: #bbbbbb;
  }

  .section-title {
    font-size: 10px;
    margin-bottom: 0.75em;
    margin-top: -0.75em;
    text-align: center;
  }

  .max {
    border-top: 1.35px dashed $globalGradient;
    height: 0;
    display: block;
    margin: 0 0.9em -0.9em;
    font-size: 12px;
  }

  .max > span {
    background: #bbbbbb;
    position: absolute;
    opacity: 0.65;
    color: #000;
  }

  .markers {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-top: -1em;
  }

  .markers h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
  }
</style>
