<template>
  <a :href="link" target="_blank" v-if="issuePresent" class="issue-warning">
    <strong class="issue-warning-title">{{ $t('global.maintenance') }}</strong>
    <p class="issue-warning-text">{{ content }}</p>
  </a>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      issuePresent: false,
      content: this.$t('global.downtime'),
      link: ''
    }
  },
  async created () {
    try {
      const status = await axios.get(`${process.env.VUE_APP_API}/status/?language=${localStorage.getItem('locale') || 'en'}`)

      if (status?.data?.length) {
        this.issuePresent = true
        this.content = status.data[0].html
        this.link = status.data[0].link
      }
    } catch (error) {
      this.issuePresent = true
    }
  }
}
</script>

<style scoped>
.issue-warning {
  width: 100%;
  padding: 8px;
  background: rgb(162, 44, 44);
  color: rgba(0, 0, 0, 0.6);
}

.issue-warning-title {
  padding-right: 15px;
}

.issue-warning-text {
  display: inline;
}
</style>
