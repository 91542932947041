<template>
  <logo class="logo" />
</template>

<script>
import Logo from '../assets/small-logo.svg'

export default {
  components: {
    Logo
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

.logo /deep/ .cls-1 {
  fill: $global;
}

.logo /deep/ .cls-2 {
  fill: #ffffff;
}
</style>
