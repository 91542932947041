function numberFormat (value) {
  if (value >= 1000000) {
    const firstPart = Math.floor(value / 1000000)
    const secondPart = Math.floor((value - (firstPart * 1000000)) / 1000).toString().padStart(3, '0')
    const thirdPart = (value - (firstPart * 1000000) - (secondPart * 1000)).toString().padStart(3, '0')
    return `${firstPart},${secondPart},${thirdPart}`
  }

  if (value >= 1000) {
    const firstPart = Math.floor(value / 1000)
    const secondPart = (value - (firstPart * 1000)).toString().padStart(3, '0')
    return `${firstPart},${secondPart}`
  }

  return value
}

export default numberFormat
