<template>
  <div class="header" :class="account.membershipTypeName">
    <div :title="$t(`account.${account.membershipTypeName}`)">
      <playstation-icon class="platform-logo" v-if="account.membershipTypeName === 'playstation'" />
      <xbox-icon class="platform-logo" v-if="account.membershipTypeName === 'xbox'" />
      <steam-icon class="platform-logo" v-if="account.membershipTypeName === 'steam'" />
      <stadia-icon class="platform-logo" v-if="account.membershipTypeName === 'stadia'" />
      <epic-icon class="platform-logo" v-if="account.membershipTypeName === 'epic'" />
    </div>
    <div class="cross-save" v-if="isCrossSave" :title="$t('account.crossSave')">
      <cross-save-icon class="cross-save-logo" />
      <span>{{ $t('account.crossSave') }}</span>
    </div>
    <span :class="['donator', { padding: isCrossSave }]" v-if="account.rank.tier">{{ account.rank.tier }}</span>
    <img v-if="account.gameVersion === 1" class="game-logo" :src="DestinyImage" :alt="$t('account.destiny1')" :title="$t('account.destiny1')" />
    <img v-else class="game-logo" :src="Destiny2Image" :alt="$t('account.destiny2')" :title="$t('account.destiny2')" />
    <a @click="remove" class="close" :title="$t('global.close')"><font-awesome icon="times" /></a>
  </div>
</template>

<script>
import PlaystationIcon from '../assets/platforms/playstation.svg'
import XboxIcon from '../assets/platforms/xbox.svg'
import SteamIcon from '../assets/platforms/steam.svg'
import StadiaIcon from '../assets/platforms/stadia.svg'
import EpicIcon from '../assets/platforms/epic.svg'
import CrossSaveIcon from '../assets/crossSave.svg'
import DestinyImage from '../assets/destiny.png'
import Destiny2Image from '../assets/destiny2.png'
import Account from '../models/account'

export default {
  components: {
    PlaystationIcon,
    XboxIcon,
    SteamIcon,
    StadiaIcon,
    EpicIcon,
    CrossSaveIcon
  },
  data () {
    return {
      DestinyImage,
      Destiny2Image
    }
  },
  computed: {
    isCrossSave () {
      return !!this.account.crossSaveActive
    }
  },
  methods: {
    remove () {
      this.$emit('remove')
    }
  },
  props: {
    account: {
      type: Account,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

.header {
  width: 100%;
  height: 25px;
  position: relative;
}

.platform-logo {
  height: 25px;
  padding: 0.3em;
  fill: #ffffff;
  stroke: #ffffff;
  position: absolute;
  left: 0.5rem;
}

.cross-save {
  height: 25px;
  position: absolute;
  left: 2.5rem;
  display: flex;
  align-items: center;
}

.cross-save span {
  color: #ffffff;
  line-height: 1;
  font-size: 9px;
  text-transform: uppercase;
  width: 50px;
}

.cross-save-logo {
  height: 25px;
  padding: 0.3em;
  fill: #ffffff;
  stroke: #ffffff;
}

.donator {
  height: 25px;
  max-width: 60px;
  position: absolute;
  display: flex;
  align-items: center;
  left: 2.5rem;
  color: #ffffff;
  font-size: 9px;
  text-transform: uppercase;
  line-height: 1;
  line-break: loose;
}

.donator.padding {
  left: 6.5rem;
}

.game-logo {
  height: 25px;
  padding: 0.3em;
  position: absolute;
  right: 3em;
}

.close {
  position: absolute;
  top: 0.125em;
  right: 1em;
  color: #ffffff;
}

.header.xbox {
  background: linear-gradient(270deg, $xbox 0, $xboxGradient);
}

.header.playstation {
  background: linear-gradient(270deg, $playstation 0, $playstationGradient);
}

.header.steam {
  background: linear-gradient(270deg, $steam 0, $steamGradient);
}

.header.stadia {
  background: linear-gradient(270deg, $stadia 0, $stadiaGradient);
}

.header.epic {
  background: linear-gradient(270deg, $epic 0, $epicGradient);
}
</style>
