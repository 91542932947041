const MembershipType = Object.freeze({
  XBOX: 1,
  PLAYSTATION: 2,
  STEAM: 3,
  STADIA: 5,
  EPIC: 6,
  UNKNOWN: -1
})

export default MembershipType
