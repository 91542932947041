function percentageFormat (value, total, round = true) {
  if (+value === 0) return '0%'
  if (+total === 0) return 'N/A'

  let percentage = (+value / +total) * 100

  if (round) {
    percentage = Math.round(percentage * 100) / 100
  }

  return percentage + '%'
}

export default percentageFormat
