<template>
  <div class="card" :data-membership-id="account.membershipId">
    <account-card-header :account="account" @remove="remove"/>
    <div class="body" :class="{ expanded: expanded }">
      <div class="top-row">
        <h2 class="name"><span v-if="account.isBungieName" class="bungie-icon" :title="$t('account.bungieName')"><tricorn-icon /></span>{{ account.displayName }}</h2>
        <v-wait :for="charactersKey">
          <small v-if="loaded" class="rank" :class="{ top: isTopRank, small: account.gameVersion !== 1 }">{{ rank }}
            <span v-if="isTopRank && account.gameVersion === 1"> on&nbsp;
              <playstation-icon class="platform-logo" v-if="account.membershipTypeName === 'playstation'" />
              <xbox-icon class="platform-logo" v-if="account.membershipTypeName === 'xbox'" />
            </span>
            <the-popover :text="$t('account.percentileInfo')">&nbsp;<font-awesome icon="question-circle" /></the-popover>
          </small>
          <font-awesome class="error" icon="exclamation-triangle" v-else />
        </v-wait>
      </div>
      <v-wait :for="charactersKey">
        <small-loader slot="waiting" />
        <div class="bottom-row" v-if="loaded">
          <h3 class="time-played">
            <span>{{ account.timePlayed }}</span>
            <small>{{ $t('account.timePlayed') }}
              <the-popover :text="$t('account.timePlayedInfo')">&nbsp;<font-awesome icon="question-circle" /></the-popover>
            </small>
          </h3>
          <h3 v-if="expanded" class="time-wasted">
            <span>{{ account.timeWasted }}</span>
            <small>{{ $t('account.timeWasted') }}
              <the-popover :text="$t('account.timeWastedInfo')">&nbsp;<font-awesome icon="question-circle" /></the-popover>
            </small>
          </h3>
          <div class="character-buttons">
            <div class="character-icons" @click="toggle" v-if="!expanded">
              <template v-for="character in account.activeCharacters">
                <img class="level-item character-icon" :key="character.characterId" v-if="character.emblemImage"
                     :src="character.emblemImage" :alt="$t('account.characterEmblem')"/>
                <div class="level-item character-icon" :key="character.characterId + '_empty'" v-else></div>
              </template>
            </div>
            <button @click="toggle" v-if="!expanded" class="level-item bottom-button view-more">
              <font-awesome icon="plus"/>
              {{ $t('account.viewMore') }}
            </button>
            <button @click="toggle" v-if="expanded" class="level-item bottom-button view-less">
              <font-awesome icon="minus"/>
              {{ $t('account.viewLess') }}
            </button>
          </div>
        </div>
        <div class="bottom-row error" v-else>
          {{ $t('account.error') }}
        </div>
      </v-wait>
    </div>
    <account-card-detail :class="{ expanding: expanded, closing: !expanded }" :account="account" @refresh="refreshLayout"/>
  </div>
</template>

<script>
import AccountCardHeader from './AccountCardHeader'
import AccountCardDetail from './AccountCardDetail'
import SmallLoader from './SmallLoader'
import Account from '../models/account'
import TricornIcon from '../assets/tricorn.svg'
import PlaystationIcon from '../assets/platforms/playstation.svg'
import XboxIcon from '../assets/platforms/xbox.svg'
import ThePopover from './ThePopover'

export default {
  components: {
    ThePopover,
    AccountCardDetail,
    AccountCardHeader,
    SmallLoader,
    TricornIcon,
    PlaystationIcon,
    XboxIcon
  },
  computed: {
    isTopRank () {
      return this.account.rank.isTop
    },
    rank () {
      if (this.account.rank.value) {
        if (this.isTopRank) {
          return this.$t('account.rankTop10', [this.account.rank.value])
        } else {
          return this.$t('account.rankPercentile', [this.account.rank.value])
        }
      }

      return ''
    },
    loaded () {
      return this.account.characters.length
    }
  },
  data () {
    return {
      expanded: false,
      charactersKey: `characters_${this.account.gameVersion}_${this.account.membershipType}_${this.account.membershipId}`,
      animating: false
    }
  },
  methods: {
    toggle () {
      if (!this.animating) {
        this.animating = true
        this.expanded = !this.expanded
        const interval = setInterval(() => {
          this.$emit('refresh')
        }, 10)
        setTimeout(() => {
          this.animating = false
          this.$emit('refresh')
          clearInterval(interval)
        }, 1000)
      }
    },
    async remove () {
      await this.$store.dispatch('accounts/remove', this.account)
    },
    refreshLayout () {
      const more = this.$el.querySelector('.more')
      const activities = more.querySelector('.activities')
      const seasons = more.querySelector('.seasons')

      const height = more.scrollHeight + 20 +
                     (activities?.clientHeight || 0) +
                     (seasons?.clientHeight || 0)
      more.style.setProperty('--max-height', height + 'px')

      this.$nextTick(() => {
        this.animating = true
        const interval = setInterval(() => {
          this.$emit('refresh')
        }, 10)
        setTimeout(() => {
          this.animating = false
          this.$emit('refresh')
          clearInterval(interval)
        }, 1000)
      })
    }
  },
  async mounted () {
    if (!this.loaded) {
      this.$wait.start(this.charactersKey)

      try {
        await this.$store.dispatch('accounts/loadCharacters', this.account)
      } catch (error) {
        let platform = ''

        switch (this.account.membershipType) {
          case 6:
            platform = ' on Epic Games'
            break
          case 5:
            platform = ' on Stadia'
            break
          case 4:
            platform = ' on Steam'
            break
          case 2:
            platform = ` on Playstation (D${this.account.gameVersion})`
            break
          case 1:
            platform = ` on Xbox (D${this.account.gameVersion})`
            break
        }

        this.$toasted.show(`No characters found for ${this.account.displayName}${platform}. Check your privacy settings on bungie.net.`, {
          theme: 'toasted-primary',
          position: 'bottom-center'
        })
        await this.$store.dispatch('accounts/remove', this.account)
      }

      this.$wait.end(this.charactersKey)
      this.refreshLayout()
    }
  },
  props: {
    account: {
      type: Account,
      required: true
    }
  },
  watch: {
    account: {
      handler () {
        setTimeout(() => {
          this.refreshLayout()
        }, 50)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/variables.scss';

  .card {
    max-width: 470px;
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    background-color: transparent;
  }

  .body {
    border-top: 1px inset rgba(0, 0, 0, 0.5);
    width: 100%;
    min-height: 128px;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 0 0 6px 6px;
    position: relative;
    z-index: 1;
  }

  .top-row {
    display: flex;
    justify-content: space-between;
  }

  .top-row .error {
    margin-top: .5rem;
    font-size: 24px;
    color: #eb2a2a;
  }

  .name {
    font-size: 24px;
    font-weight: 300;
  }

  .rank {
    font-weight: 500;
  }

  .rank.top {
    display: block;
    border: 2px solid #dd0;
    border-radius: 16px;
    height: 24px;
    line-height: 22px;
    width: 80px;
    text-align: center;
    box-shadow: 0 0 10px 0 rgba(221,221,0,0.65);
  }

  .rank.small {
    width: 50px;
  }

  .rank .platform-logo {
    width: 12px;
    margin-left: -2px;
  }

  .bungie-icon {
    display: inline-block;
    width: 18px;
    margin-left: -2px;
    margin-right: 6px;
  }

  .bottom-row {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .bottom-row.error {
    display: flex;
    width: 100%;
    height: 36px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #eb2a2a;
  }

  .time-played {
    display: flex;
    flex-direction: column;
  }

  .time-played span {
    font-size: 26px;
    font-weight: 700;
    line-height: 1;
  }

  .time-played small {
    margin: 0 0 -8px;
    font-size: 12px;
  }

  .character-buttons {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .character-icons {
    display: flex;
    flex-wrap: wrap;
  }

  .character-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    overflow: hidden;
    margin: 0.25em 0.25em 0 0 !important;
    background: #000000;
    cursor: pointer;
  }

  .time-wasted {
    display: flex;
    flex-direction: column;
  }

  .time-wasted span {
    font-size: 26px;
    font-weight: 700;
    line-height: 1;
    color: #eb2a2a;
  }

  .time-wasted small {
    margin: 0 0 -8px;
    font-size: 12px;
  }

  .bottom-button {
    width: 90px;
    height: 32px;
    border-radius: 16px;
    line-height: 1;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: left;
    padding-left: 33px;
    position: relative;
    margin-right: 0.25rem !important;
    cursor: pointer;
  }

  .bottom-button svg {
    position: absolute;
    left: 0.25em;
    top: 0;
  }

  .view-more {
    // background-image: linear-gradient(163deg, #6c82e9, #70489a 99%);
    background-image: linear-gradient(163deg, $global, $globalGradient 99%);
    border: 0;
    color: #ffffff;
  }

  .view-more svg {
    height: 32px;
    width: 32px;
    padding: 8px;
  }

  .view-more:hover {
    // background-image: linear-gradient(163deg, #9aadff, #945FCB 99%);
    box-shadow: inset 0 0 150px 0 rgba(255, 255, 255, 0.2);
  }

  .view-less {
    background-color: #ffffff;
    border: solid 2px $global;
    color: $global;
  }

  .view-less svg {
    height: 28px;
    width: 28px;
    padding: 6px;
  }

  .view-less:hover {
    border: solid 2px $globalGradient;
    color: $globalGradient;
  }

  .more {
    transition: max-height 1s, padding-top 1s, padding-bottom 1s;
    overflow: hidden;
  }

  .more.closing {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .more.expanding {
    max-height: var(--max-height);
  }

  .uk-icon {
    margin: 0 12px;
    height: 16px;
    fill: #00aeef;
    vertical-align: middle;
  }
</style>
