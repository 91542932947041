<template>
  <div v-if="!isNoAdsDomain">
    <div class="card big-advert-card has-background-black is-hidden-mobile">
      <small>{{ $t('global.advertTitle') }}</small>
      <a class="please" href="https://patreon.com/wastedondestiny">
        <patreon-icon class="patreon-logo" />
        <p>{{ $t('global.advertMessage') }}</p>
      </a>
      <adsense-block class="big-advert" :client="campaign" :ad-slot="bigBlock" format="leaderboard" responsive="false"/>
    </div>
    <div class="card small-advert-card has-background-black is-hidden-tablet">
      <small>{{ $t('global.advertTitle') }}</small>
      <a class="please" href="https://patreon.com/wastedondestiny">
        <patreon-icon class="patreon-logo" />
        <p>{{ $t('global.advertMessage') }}</p>
      </a>
      <adsense-block class="small-advert" :client="campaign" :ad-slot="smallBlock" format="leaderboard" responsive="false"/>
    </div>
  </div>
</template>

<script>
import '../helpers/noAdsDomainChecker.min.js'
import AdsenseBlock from './AdsenseBlock'
import PatreonIcon from '../assets/patreon.svg'

export default {
  components: {
    AdsenseBlock,
    PatreonIcon
  },
  data () {
    return {
      campaign: process.env.VUE_APP_AD_CAMPAIGN,
      smallBlock: process.env.VUE_APP_AD_SMALL,
      bigBlock: process.env.VUE_APP_AD_BIG
    }
  },
  computed: {
    isNoAdsDomain () {
      return window.checkNoAdsDomain()
    }
  }
}
</script>

<style scoped>
  small {
    color: #888888;
  }

  .please {
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1.5rem 1.5rem;
    color: #ffffff;
  }

  .please p {
    text-align: left;
    padding: 1rem 0 1rem 1rem;
  }

  .patreon-logo {
    fill: currentColor;
    height: 90px;
  }

  .big-advert-card {
    width: 768px;
    height: 130px;
    margin: 0 auto 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .small-advert-card {
    height: 130px;
    margin: 0 auto 1em;
    width: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .big-advert[data-ad-status="unfilled"],
  .small-advert[data-ad-status="unfilled"] {
    pointer-events: none;
  }

  .big-advert {
    width: 728px !important;
    height: 90px !important;
    overflow: hidden;
    display: block;
  }

  .small-advert {
    width: 320px !important;
    height: 100px !important;
    overflow: hidden;
    display: block;
  }

  @media screen and (max-width: 768px) {
    .please p {
      font-size: 14px;
    }
  }
</style>
